/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Link, useLocation } from 'react-router-dom'
import axios from 'axios';

const ProfileHeader2: React.FC = () => {
    const location = useLocation()
    const [orgData, setOrgData] = useState<any | null>(null);

    // Fetch organization data from the backend
    useEffect(() => {
        axios.get('/reg/v1/admin/organization/1')
            .then((response) => {
                const data = response.data[0];
                setOrgData(data);

            })
            .catch((error) => {
                console.log(error);
                console.error("Error fetching organization data:", error);
            });
    }, []);

    return (
        <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-0'>
                <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                    <div className='me-7 mb-4'>
                        <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                            <img src={toAbsoluteUrl('/media/stock/600x600/img-21.jpg')} alt='Metornic' />
                            <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
                        </div>
                    </div>

                    <div className='flex-grow-1'>
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                            <div className='d-flex flex-column'>
                                <div className='d-flex align-items-center mb-2'>
                                    <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                                        {orgData ? orgData.name : 'Organization Name'}
                                    </a>
                                    <a href='#'>
                                        <KTIcon iconName='verify' className='fs-1 text-primary' />
                                    </a>
                                </div>
                                <div className='d-flex flex-wrap fw-bold fs-6 mb-2 pe-2'>
                                    <a
                                        href='#'
                                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                                    >
                                        <KTIcon iconName='home' className='fs-4 me-1' />
                                        Organization Type -
                                        {orgData && orgData.industry ? orgData.industry : ' '}
                                    </a>
                                </div>

                                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                                    <a
                                        href='#'
                                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                                    >
                                        <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                                        Admin -
                                        {orgData ? orgData.fullname : ' '}
                                    </a>
                                    <a
                                        href='#'
                                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                                    >
                                        <KTIcon iconName='geolocation' className='fs-4 me-1' />
                                        {orgData &&
                                            [
                                                orgData.address,
                                                orgData.city,
                                                orgData.state,
                                                orgData.region,
                                                orgData.country,
                                            ]
                                                .filter((item) => item) // Filter out null or empty items
                                                .join(', ')}                                    </a>
                                    <a
                                        href='#'
                                        className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                                    >
                                        <KTIcon iconName='sms' className='fs-4 me-1' />
                                        {orgData && orgData.website ? orgData.website : ''}
                                    </a>
                                </div>
                            </div>


                        </div>

                        {/* <div className='d-flex flex-wrap flex-stack'>
                            <div className='d-flex flex-column flex-grow-1 pe-8'>
                                <div className='d-flex flex-wrap'>
                                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                        <div className='d-flex align-items-center'>
                                            <KTIcon iconName='user-tick' className='fs-1 text-success me-2' />

                                            <div className=' fw-bold fs-6 text-400'>10</div>
                                            <div className='mx-2 fw-bold fs-6 text-400'>Users</div>
                                        </div>


                                    </div>




                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className='d-flex  h-55px'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/admin/users' && 'active')
                                }
                                to='/admin/users'
                            >
                                Users
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/admin/settings' && 'active')
                                }
                                to='/admin/settings'
                            >
                                Settings
                            </Link>
                        </li>



                    </ul>
                </div>
            </div>
        </div>
    )
}

export { ProfileHeader2 }
