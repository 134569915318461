import React, { useState, useEffect } from 'react';
import { ID, initialQueryState } from "../../../../../../../../_metronic/helpers";
import { useQueryRequest } from '../../core/QueryRequestProvider'
import { MenuComponent } from '../../../../../../../../_metronic/assets/ts/components'

interface Organization {
    id: ID;
    name: string;
    primaryuser: number;
    website: string;
    industry: string;
    address: string;
    city: string;
    state: string;
    region: string;
    country: string;
    zip: string;
    phone: string;
    language: string;
    is_deleted: number;
    is_active: number;
    start_date: number;
    end_date: string;
    is_partner: number;
    parent_id: number;
    create_date: number;
    modified_date: number;
}

function OrgListDropdown() {
    const [org, setOrg] = useState<Organization[]>([]);
    const [selectedorg, setSelectedOrg] = useState('1');
    const { updateState } = useQueryRequest()

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    useEffect(() => {
        fetch('/reg/v1/superadmin/org')
            .then((response) => response.json())
            .then((data: Organization[]) => { // Specify the type here as well
                setOrg(data);
            })
            .catch((error) => {
                console.error('Error fetching options:', error);
            });
    }, []);

    useEffect(
        () => {
            updateState({ org: selectedorg, ...initialQueryState });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [selectedorg]);

    const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newSelectedOrg = event.target.value;
        setSelectedOrg(newSelectedOrg);
    };

    return (
        <div className='me-3'>
            <select
                className='form-select form-select-solid fw-bolder'
                data-kt-select2='true'
                data-placeholder='Select Organization'
                data-allow-clear='true'
                data-kt-user-table-filter='org'
                onChange={handleDropdownChange}
                value={selectedorg}
            >
                {org.map((org) => (
                    <option key={org.id} value={org.id?.toString()}>
                        {org.name}
                    </option>
                ))}
            </select>
        </div>
    );
}

export { OrgListDropdown }