import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios'; // Import axios
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { KTIcon } from '../../../_metronic/helpers';



const Comments = () => {
    const selectedDocket = useSelector((state) => state.dockets.selectedDocket);
    const [selectedDocument, setSelectedDocument] = useState('');
    const [mydocuments, setMydocuments] = useState([]);
    const [comments, setComments] = useState([]);
    const [activeBreadcrumb, setActiveBreadcrumb] = useState('Document Details');
    const [documentDetails, setDocumentDetails] = useState([])// Default to 'Document Details'


    useEffect(() => {
        if (selectedDocket && selectedDocket.iddocket) {
            // Make an API call to fetch My Dockets data
            axios.get(`/reg/v1/document/3/${selectedDocket.iddocket}`)
                .then((response) => {
                    const { data } = response;
                    setMydocuments(data);
                    if (data.length > 0) {
                        setSelectedDocument(data[0].objectId);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching My Dockets:", error);
                    setMydocuments([]);
                });
        } else {
            setMydocuments([]);
        }
    }, [selectedDocket]);


    useEffect(() => {
        if (selectedDocument) {
            // Make an API call to fetch comments for the selected document
            axios.get(`/reg/v1/comment/${selectedDocument}`)
                .then((response) => {
                    console.log(selectedDocument);
                    const { data } = response;
                    setComments(data);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 404) {
                        // Handle 404 error by setting comments to an empty array
                        setComments([]);
                    } else {
                        console.error("Error fetching comments:", error);
                        setComments([]);
                    }
                });
        } else {
            // Clear comments when selectedDocument is empty
            setComments([]);
        }
    }, [selectedDocument]);

    useEffect(() => {
        if (selectedDocument) {
            // Make an API call to fetch comments for the selected document
            axios.get(`/reg/v1/document/${selectedDocument}`)
                .then((response) => {
                    const { data } = response;
                    setDocumentDetails(data);
                })
                .catch((error) => {
                    if (error.response && error.response.status === 404) {
                        // Handle 404 error by setting comments to an empty array
                        setDocumentDetails([]);
                    } else {
                        console.error("Error fetching document details:", error);
                        setDocumentDetails([]);
                    }
                });
        } else {
            // Clear comments when selectedDocument is empty
            setDocumentDetails([]);
        }
    }, [selectedDocument]);

    const handleDocumentChange = (e) => {
        setSelectedDocument(e.target.value);
        // console.log(selectedDocument);
    };


    return (
        // <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
        //     <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
        //         <HeaderWrapper />
        //         <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
        //             <Sidebar />
        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            <div className='d-flex flex-column flex-column-fluid'>
                <div className="d-flex flex-column-auto h-60px   mb-5 pe-6 ">
                    <div className="d-flex flex-row-fluid me-auto m-5 ">
                        <div className="d-flex align-items-center ">
                            <span className="fs-7 fw-bold text-black-700 flex-shrink-0 pe-4 d-none d-md-block">
                                Documents
                            </span>
                            <select
                                className="form-select form-select-sm w-600px form-select-solid me-6"
                                data-control="select2"
                                data-placeholder="Select Docket"
                                value={selectedDocument}
                                onChange={handleDocumentChange}
                            >
                                {/* <option value="">Select Document</option> */}
                                {mydocuments.length === 0 && (
                                    <option value="" disabled>
                                        No Documents found for selected Docket
                                    </option>
                                )}
                                {mydocuments.map((document) => (
                                    <option key={document.iddocument} value={document.objectId}>
                                        {document.title}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="d-flex align-items-center ms-auto">
                            {selectedDocument && (
                                <button
                                    type="button"
                                    className="btn btn-primary h-40px d-flex align-items-center justify-content-center"
                                    data-bs-toggle="modal"
                                    data-bs-target="#kt_modal_2"
                                >
                                    <KTIcon iconName='messages' className='fs-2  ' /> Comment
                                </button>
                            )}
                            <div className="modal fade" tabIndex={-1} id="kt_modal_2" >
                                <div className="modal-dialog modal-fullscreen" >
                                    <div className="modal-content shadow-none" style={{ width: '100%' }} >
                                        <div className="modal-header  d-flex justify-content-between align-items-center" style={{ width: '100%' }}>
                                            <div className="w-100 text-center">
                                                <h3 className="modal-title text-black fw-bold fs-3" style={{ color: 'black' }}>
                                                    {/* You are commenting on the document <br /> */}
                                                    {documentDetails.length > 0 ? documentDetails[0].title : ''}
                                                </h3>
                                            </div>
                                            <div>
                                                <span className="badge bg-warning">Comment period ends: {documentDetails.length > 0 ? (new Date(documentDetails[0].commentEndDate).toLocaleDateString()) : ''}</span>
                                            </div>
                                        </div>

                                        <div className="modal-body text-start" style={{ width: '100%' }}>
                                            <h3 className="mb-4">Write a Comment </h3>
                                            {/* <div className="mb-10">
                                                        sfs
                                                            <label className="form-label required form-label">Comment</label>
                                                            <textarea
                                                                type="text"
                                                                className="form-control form-control-solid"
                                                                rows="7"
                                                                placeholder="Start typing comment here..."
                                                                maxLength="5000"
                                                            />
                                                        </div> */}
                                            <Form>

                                                <Form.Group className="mb-3 " controlId="exampleForm.ControlTextarea1">
                                                    <Form.Label className="form-label required form-label">Comment</Form.Label>
                                                    <Form.Control as="textarea" rows={7} maxLength="5000" placeholder={'Start typing comment here...'} className="form-control form-control-solid" />
                                                </Form.Group>
                                                <Form.Group controlId="formFileMultiple" className="mb-3">
                                                    <Form.Label>Attach files</Form.Label>
                                                    <Form.Control type="file" multiple />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Email address</Form.Label>
                                                    <Form.Control type="email" placeholder="name@example.com" />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Tell us about yourself! I am..</Form.Label>
                                                    <Form.Select aria-label="Default select example">

                                                        <option value="1">An Individual</option>
                                                        <option value="2">An Organization</option>
                                                        <option value="3">Anonymous</option>
                                                    </Form.Select>
                                                </Form.Group>
                                                <Button variant="primary" type="submit" className='mt-5'>
                                                    Submit
                                                </Button>

                                            </Form>


                                            <p className='mt-10'>
                                                <strong>Important Notice:</strong> Do not submit personally identifiable information through this form.
                                                Any personally identifiable information (e.g., name, address, phone number) included in the comment form or in an attachment
                                                may be publicly disclosed in a docket or on the Internet (via Regulations.gov, a federal agency website, or a third-party,
                                                non-government website with access to publicly-disclosed data on Regulations.gov).
                                            </p>


                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn btn-danger"
                                                data-bs-dismiss="modal"
                                            >
                                                Close
                                            </button>
                                            {/* <button type="button" className="btn btn-primary">
                                                            Save changes
                                                        </button> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column-auto h-70px  mt-5 pe-6 bg-secondary bg-gradient ">
                    <div className="d-flex flex-row-fluid me-auto m-5 ">
                        <ol className="breadcrumb breadcrumb-separatorless text-muted fs-6 fw-bold">
                            <li className={`breadcrumb-item pe-3 ${activeBreadcrumb === 'Document Details' ? 'active' : ''}`}>
                                <button type="button" className="btn btn-link pe-3 text-primary" onClick={() => setActiveBreadcrumb('Document Details')}>
                                    Document Details
                                </button>
                            </li>
                            <li className={`breadcrumb-item pe-3 ${activeBreadcrumb === 'Comments' ? 'active' : ''}`}>
                                <button type="button" className="btn btn-link pe-3 text-primary" onClick={() => setActiveBreadcrumb('Comments')}>
                                    Comments
                                </button>
                            </li>

                        </ol>

                    </div>
                </div>
                <div className="d-flex flex-column flex-row-fluid p-2">
                    {activeBreadcrumb === 'Document Details' ? (
                        <div className="container mt-4">
                            {documentDetails.map((detail) => (
                                <div className="card mb-4" key={detail.iddocument}>
                                    <div className="card-body">
                                        <table className="table table-bordered table-striped">
                                            <tbody>
                                                <tr className="table-primary">
                                                    <th>Document ID:</th>
                                                    <td>{detail.id}</td>
                                                </tr>
                                                <tr>
                                                    <th>Title:</th>
                                                    <td>{detail.title}</td>
                                                </tr>
                                                <tr>
                                                    <th>Document Type:</th>
                                                    <td>{detail.documentType}</td>
                                                </tr>
                                                <tr>
                                                    <th>Start-End Page:</th>
                                                    <td>{detail.startEndPage}</td>
                                                </tr>
                                                <tr>
                                                    <th>Docket ID:</th>
                                                    <td>{detail.docketId}</td>
                                                </tr>

                                                <tr>
                                                    <th>Comment Start Date:</th>
                                                    <td>{new Date(detail.commentStartDate).toLocaleDateString()}</td>
                                                </tr>
                                                <tr>
                                                    <th>Comment End Date:</th>
                                                    <td>{new Date(detail.commentEndDate).toLocaleDateString()}</td>
                                                </tr>


                                                <tr>
                                                    <th>Allow Late Comments:</th>
                                                    <td>{detail.allowLateComments ? 'Yes' : 'No'}</td>
                                                </tr>
                                                <tr>
                                                    <th>Effective Date:</th>
                                                    <td>{detail.effectiveDate}</td>
                                                </tr>
                                                <tr>
                                                    <th>FR Doc Num:</th>
                                                    <td>{detail.frDocNum}</td>
                                                </tr>
                                                <tr>
                                                    <th>Subject:</th>
                                                    <td>{detail.subject}</td>
                                                </tr>
                                                <tr>
                                                    <th>Topics:</th>
                                                    <td>{detail.topics}</td>
                                                </tr>
                                                <tr>
                                                    <th>Original Document ID:</th>
                                                    <td>{detail.originalDocumentId}</td>
                                                </tr>
                                                <tr>
                                                    <th>Page Count:</th>
                                                    <td>{detail.pageCount}</td>
                                                </tr>
                                                <tr>
                                                    <th>Posted Date:</th>
                                                    <td>{new Date(detail.postedDate).toLocaleDateString()}</td>
                                                </tr>
                                                <tr>
                                                    <th>Receive Date:</th>
                                                    <td>{new Date(detail.receiveDate).toLocaleDateString()}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ))}
                        </div>

                    ) : (
                        <div>
                            <div className="d-flex flex-column-auto h-60px flex-center">
                                <span className="text-black fw-bold fs-3">Comments</span>
                            </div>
                            {comments.length > 0 ? (
                                comments.map((comment) => (
                                    <div className="card mb-4 " key={comment.idcomment}>
                                        <div className="card-header bg-light bg-gradient">
                                            <h4 className="card-title mb-0 d-flex align-items-center justify-content-center">
                                                <KTIcon iconName='message-text' className='fs-2 mx-2 text-primary' /> Comment from
                                                {comment.firstName || comment.lastName
                                                    ? ` ${comment.firstName} ${comment.lastName}`
                                                    : ` ${comment.organization}`}


                                            </h4>
                                            <div className="card-toolbar">
                                                {comment.attachments && (
                                                    <div>
                                                        <a
                                                            href={comment.attachments}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="btn btn-success attachment-button h-10px"
                                                            style={{ display: 'inline-flex', alignItems: 'center' }}
                                                        >
                                                            <KTIcon iconName='paper-clip' className='fs-2 text-white ' />
                                                            Attachment
                                                        </a>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="card-body ">
                                            <div className="mb-3">
                                                <div className="flex ">
                                                    <span className="fw-bold">Comment:</span>
                                                    <span className="fw-normal mx-2">{comment.comment}</span>
                                                </div>
                                            </div>

                                            {/* <hr /> */}
                                            <div className="d-flex justify-content-between align-items-center ">

                                                <div>
                                                    <span className="fw-bold">Posted  </span> {new Date(comment.postedDate).toLocaleDateString()}
                                                </div>
                                                <div>
                                                    <span className="fw-bold">Recieved </span>{new Date(comment.receiveDate).toLocaleDateString()}
                                                </div>
                                                <div>
                                                    <span className="fw-bold">Agency  </span>{comment.agencyId}
                                                </div>
                                                <div>
                                                    <span className="fw-bold">ID</span> {comment.id}
                                                </div>


                                            </div>
                                        </div>


                                    </div>
                                ))
                            ) : (
                                <div>No comments</div>
                            )}
                        </div>

                    )}
                </div>
            </div>
        </div>
        //         </div>
        //     </div>


        // </div>

    );
};

export default Comments;
