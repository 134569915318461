/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect, useCallback, memo } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import {
  ListsWidget2,
  ListsWidget6,
  MixedWidget8,
  CardsWidget20,
  ListsWidget26,
} from '../../../_metronic/partials/widgets'
import axios from 'axios'
// import { useNavigate } from 'react-router-dom'
import { ErrorBoundary } from "react-error-boundary";


const fetchData = async (url, setData) => {
  try {
    const response = await axios.get(url);
    if (response.status === 200 || response.status === 201) {
      setData(response.data);
    } else {
      throw new Error(`Request failed with status code ${response.status}`);
    }
  } catch (error) {
    throw error;
  }
};

const DashboardPage: FC = () => {
  // const navigate = useNavigate();
  const [retry, setRetry] = useState(0);
  const [customData, setCustomData] = useState([]);
  const [docketsdata, setDocketsdata] = useState({ labels: [], values: [] });
  const [documentsdata, setDocumentsdata] = useState({ labels: [], values: [] });
  const [commentsdata, setCommentsdata] = useState({ labels: [], values: [] });
  const [topicsdata, setTopicsdata] = useState({ labels: [], values: [] });
  const [sentimentdata, setSentimentdata] = useState({ labels: [], values: [] });
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fetchCustomData = useCallback(() => fetchData('/home/org/1', setCustomData), []);
  const fetchGraphData = useCallback(() => fetchData('/home/bar_graph1', (data) => {
    setDocketsdata(data[0]);
    setDocumentsdata(data[1]);
    setCommentsdata(data[2]);
  }), []);
  const fetchTopicsData = useCallback(() => fetchData('/home/topics', setTopicsdata), []);
  const fetchSentimentData = useCallback(() => fetchData('/home/sentiment', setSentimentdata), []);

  useEffect(() => {
    setIsLoading(true);
    setHasError(false);
    Promise.all([
      fetchCustomData(),
      fetchGraphData(),
      fetchTopicsData(),
      fetchSentimentData()
    ]).then(() => { setIsLoading(false); }).catch((error) => {
      setIsLoading(false);
      setHasError(error.message);
    });
  }, [fetchCustomData, fetchGraphData, fetchTopicsData, fetchSentimentData, retry]);

  // const intl = useIntl();


  // const redirectcheck = () => {
  //   console.log('Redirecting to check page...');
  //   axios.get('/home/redirect', {
  //     validateStatus: function (status) {
  //       return (status >= 200 && status < 300) || status === 302; // default
  //     },
  //   })
  //     .then((response) => {
  //       console.log(response);
  //       if (response.status === 302) {
  //         navigate(response.data.redirectUrl);
  //       } else if (response.status === 200 || response.status === 201) {
  //         console.log(response);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }
  if (hasError) {
    return (
      <div>
        <div className="alert alert-danger" role="alert">{hasError}</div>
        <div><button type="button" className="btn btn-primary" onClick={() => setRetry(retry + 1)}>Try again</button></div>
      </div>

    );
  }
  return (
    <ErrorBoundary fallback={<div>
      <div className="alert alert-danger" role="alert">Something went wrong!</div>
      <div><button type="button" className="btn btn-primary" onClick={() => setRetry(retry + 1)}>Try again</button></div>
    </div>}>

      <>

        <div className='row g-5 g-xl-10  '>



          <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-4 mb-md-5 mb-xl-10'>
            <CardsWidget20
              className='h-md-100 mb-5 mb-xl-10'
              description='Agency Subscriptions'
              color='#F1416C'
              img={toAbsoluteUrl('/media/patterns/vector-1.png')}
              customData={customData}
            />

          </div>
          <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-8 mb-md-5 mb-xl-10'>

            <ListsWidget26 className='h-md-100 mb-5 mb-xl-10' />
          </div>

        </div>


        <div className='row g-5 gx-xxl-7'>
          <div className='col-xxl-4'>
            <MixedWidget8
              className='card-xxl-stretch  mb-xl-3'
              chartColor='#00ced1'
              chartHeight='300px'
              docketsdata={docketsdata}
              title="Dockets"
            />
          </div>
          <div className='col-xxl-4'>
            <MixedWidget8
              className='card-xxl-stretch mb-xl-3'
              chartColor='#45f19c'
              chartHeight='300px'
              docketsdata={documentsdata}
              title="Documents"

            />
          </div>
          <div className='col-xxl-4'>
            <MixedWidget8
              className='card-xxl-stretch mb-xl-3'
              chartColor='#8a2be2'
              chartHeight='300px'
              docketsdata={commentsdata}
              title="Comments"

            />
          </div>
        </div>
        <div className='row gy-5 g-xl-8'>
          <div className='col-xl-6'>
            <ListsWidget2 className='card-xl-stretch mb-xl-8' topicsdata={topicsdata} />
          </div>
          <div className='col-xl-6'>
            <ListsWidget6 className='card-xl-stretch mb-xl-8' sentimentdata={sentimentdata} />
          </div>

        </div>
        {/* <button onClick={redirectcheck}>Click me!</button> */}
      </>

    </ErrorBoundary>
  );


};
const MemoizedDashboardPage = memo(DashboardPage);

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>

      <MemoizedDashboardPage />

    </>
  )
}

export { DashboardWrapper }
