import React, { useState, useEffect, useCallback } from 'react';
import { KTIcon } from '../../../_metronic/helpers';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DocketFilter from './DocketFilter';



const Docket = () => {

    const [dockets, setDockets] = useState([]);
    const [filteredDockets, setFilteredDockets] = useState([]); // Filtered list of dockets
    const [error, setError] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const [filterDocket, setFilterDocket] = useState('');
    const [filterStatus, setFilterStatus] = useState('');
    const [filterStartDate, setFilterStartDate] = useState('');
    const [filterEndDate, setFilterEndDate] = useState('');
    const [filterStarred, setFilterStarred] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [alert, setAlert] = useState({ message: '', type: '' });


    const navigate = useNavigate();
    
    useEffect(() => {
        fetchDockets(); // Fetch dockets when component mounts
    },[]); // Empty dependency array to mimic componentDidMount behavior

    useEffect(() => {
        if (sortConfig.key) {
            sortDockets(); // Sort dockets when sortConfig or dockets change
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortConfig]);

    useEffect(() => {
        // If the search input is empty, set filteredDockets to all dockets
        if (searchInput.trim() === '') {
            setFilteredDockets(dockets);
        } else {
            // Otherwise, filter dockets based on the search input
            const searchTerm = searchInput.toLowerCase();
            const filtered = dockets.filter((docket) => {
                return (
                    
                    docket.id.toLowerCase().includes(searchTerm) ||
                    docket.title.toLowerCase().includes(searchTerm) ||
                    docket.docketType.toLowerCase().includes(searchTerm)
                );
            });
            setFilteredDockets(filtered);
        }
    }, [searchInput, dockets]);





    // fetching dockets asynchronously
    const fetchDockets = async () => {
        try {
            const { data } = await axios.get('/reg/v1/docket/user/3/1');
            if (Array.isArray(data)) {
                setDockets(data);
                setFilteredDockets(data);
                setError(null); // Clear any previous errors
            } else {
                setError('API Response does not contain an array of dockets.');
            }
        } catch (error) {
            // if (error.response && error.response.status === 404) {
            //     navigate('/error/404'); // Redirect to the 404 error route
            // } else if (error.response && error.response.status === 500) {
            //     navigate('/error/500');
            // } else {
                setError('Unable to fetch dockets: ' + error.message);
            // }
        }
    }

    const sortDockets = useCallback(() => {
        const sortedDockets = [...filteredDockets];

        if (sortConfig.key === 'title') {
            sortedDockets.sort((a, b) => {
                if (a.title < b.title) return sortConfig.direction === 'asc' ? -1 : 1;
                if (a.title > b.title) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            });
        }
        else if (sortConfig.key === 'id') {
            sortedDockets.sort((a, b) => {
                if (a.id < b.id) return sortConfig.direction === 'asc' ? -1 : 1;
                if (a.id > b.id) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            });

        } else if (sortConfig.key === 'date') {
            sortedDockets.sort((a, b) => {
                if (a.modifyDate < b.modifyDate) return sortConfig.direction === 'asc' ? -1 : 1;
                if (a.modifyDate > b.modifyDate) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            });
        }
        else if (sortConfig.key === 'docketType') {
            // Sort by Docket Type
            sortedDockets.sort((a, b) => {
                if (a.docketType < b.docketType) return sortConfig.direction === 'asc' ? -1 : 1;
                if (a.docketType > b.docketType) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            });
        } else if (sortConfig.key === 'is_starred') {
            sortedDockets.sort((a, b) => {
                if (a.is_starred < b.is_starred) return sortConfig.direction === 'asc' ? -1 : 1;
                if (a.is_starred > b.is_starred) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            });
        } else if (sortConfig.key === 'is_archived') {
            sortedDockets.sort((a, b) => {
                if (a.is_archived < b.is_archived) return sortConfig.direction === 'asc' ? -1 : 1;
                if (a.is_archived > b.is_archived) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            });
        }


        setFilteredDockets(sortedDockets);
    }, [sortConfig, filteredDockets]);

    const applyFilters = () => {
        let filtered = dockets; // Start with the original list


        // Apply filters one by one
        if (filterDocket) {
            filtered = filtered.filter((docket) =>
                docket.id.toLowerCase().includes(filterDocket.toLowerCase())
            );
        }
        if (filterStatus) {
            filtered = filtered.filter((docket) => docket.docketType === filterStatus);
        }
        if (filterStartDate) {
            const startTimestamp = new Date(filterStartDate).getTime(); // Default to 0 if filterStartDate is empty or null

            filtered = filtered.filter((docket) => {
                const modifyDateTimestamp = new Date(docket.modifyDate).getTime();
                return modifyDateTimestamp >= startTimestamp
            }
            );
        }
        if (filterEndDate) {
            const endTimestamp = new Date(filterEndDate).getTime(); // Default to max value if filterEndDate is empty or null

            filtered = filtered.filter((docket) => {
                const modifyDateTimestamp = new Date(docket.modifyDate).getTime();
                return modifyDateTimestamp <= endTimestamp
            });
        }
        if (filterStarred !== false) {
            filtered = filtered.filter((docket) => docket.is_starred === 1);
        }

        setFilteredDockets(filtered);
    };

    const handleApplyFilters = () => {
        applyFilters();
    };
    const handleResetFilters = () => {
        // Clear filter state variables
        setFilterDocket('');
        setFilterStatus('');
        setFilterStartDate('');
        setFilterEndDate('');
        setFilterStarred(false);
        setFilteredDockets(dockets);
    };




    // Filter dockets based on the selected filter



    const handleHeaderClick = (key) => {
        let newDirection = 'asc';

        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            newDirection = 'desc';
        }

        setSortConfig({ key, direction: newDirection });
    }

    // do fetchdockets when we enter into dockets page

    // changing date-time format from international to local
    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString();
    };
    const handleManageSubscriptionsClick = () => {
        navigate('/subscriptions'); // Navigate to the "Manage Subscriptions" page-under MySubscriptions component
    };
    const handleStarDocket = (docketid) => {
        // const endpoint = `/reg/v1/docket/star/3/${docketid}`;
        const endpoint = `/reg/v1/docket/3/${docketid}/star`;

        // Send a POST request to subscribe or unsubscribe
        axios.get(endpoint)
            .then((response) => {
                console.log(response.data);
                fetchDockets();
                
            })
            .catch((error) => {
                // Handle errors, e.g., show an error message
                console.log(error);
                console.error('Error starring/', error);
            });


    }
    const handleUnstarDocket = (docketid) => {
        // const endpoint = `/reg/v1/docket/star/3/${docketid}`;
        const endpoint = `/reg/v1/docket/3/${docketid}/star`;

        // Send a POST request to subscribe or unsubscribe
        axios.get(endpoint)
            .then((response) => {


                fetchDockets();

            })
            .catch((error) => {
                // Handle errors, e.g., show an error message
                console.log(error);

                console.error('Error unstarring/', error);
            });

    }
    const handleArchiveDocket = (docketid) => {
        const endpoint = `/reg/v1/docket/3/${docketid}/archive`;

        // Send a POST request to subscribe or unsubscribe
        axios.get(endpoint)
            .then((response) => {
                setAlert({ message: response.data, type: 'success' }); // Set the success alert
                scrollToTop();

                fetchDockets();
                // Handle a successful subscription or unsubscription
                // You can update the UI or show a success message
                // For example, you can refresh the list of dockets after subscription
                // Define a function to fetch dockets
            })
            .catch((error) => {
                // Handle errors, e.g., show an error message
                setAlert({ message: error.message, type: 'error' }); // Set the error alert

                console.error('Error archiving/', error);
                scrollToTop();
            });

    }
    const handleUnarchiveDocket = (docketid) => {
        const endpoint = `/reg/v1/docket/3/${docketid}/archive`;

        // Send a POST request to subscribe or unsubscribe
        axios.get(endpoint)
            .then((response) => {



                fetchDockets();

            })
            .catch((error) => {
                // Handle errors, e.g., show an error message
                console.log(error);

                console.error('Error unarchiving/', error);
            });

    }
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'auto' // You can use 'auto' for an instant scroll
        });
    };

    return (

        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            <div className='d-flex flex-column flex-column-fluid'>
                <div className="d-flex flex-column-auto h-70px flex-center p-4">
                    <div className="d-flex flex-row-fluid flex-center text-center">
                        <div className="d-flex flex-column-auto h-60px flex-center ms-auto ">
                            <span className="text-black fw-bold fs-3">DOCKETS</span>
                        </div>


                        <div className="d-flex flex-column-auto  flex-center ms-auto m-5">
                            <button className="btn btn-primary" onClick={handleManageSubscriptionsClick}>
                                <KTIcon iconName='user-tick' className='fs-2' />
                                My Subscriptions
                            </button>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column-auto  align-items-center  m-3 ">
                    <div className="d-flex flex-column-auto  align-items-center  ">
                        <div className="d-flex align-items-center position-relative my-1  ">
                            <KTIcon iconName='magnifier' className='fs-2 position-absolute ms-6' />
                            <input
                                type="search"
                                id="form1"
                                className="form-control form-control-solid w-250px ps-14 "
                                placeholder="Search all columns..."
                                aria-label="Search"
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                                maxLength={200}
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-column-auto  mx-4   ">
                        <button
                            type='button'
                            className='btn btn-light-primary btn-active-light  d-flex align-items-center justify-content-center '
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'
                        >

                            <KTIcon iconName='filter' className='fs-2' /> Filter

                        </button>
                        <DocketFilter
                            filterDocket={filterDocket}
                            setFilterDocket={setFilterDocket}
                            filterStatus={filterStatus}
                            setFilterStatus={setFilterStatus}
                            filterStartDate={filterStartDate}
                            setFilterStartDate={setFilterStartDate}
                            filterEndDate={filterEndDate}
                            setFilterEndDate={setFilterEndDate}
                            filterStarred={filterStarred}
                            setFilterStarred={setFilterStarred}
                            onApplyFilters={handleApplyFilters}
                            onResetFilters={handleResetFilters}
                            dockets={dockets}
                        />
                    </div>
                </div>

                <div className="d-flex flex-column flex-row-fluid p-2">
                    {alert.message && (
                        <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
                            {alert.message}
                            <button type="button" className="btn-close" onClick={() => setAlert({ message: '', type: '' })} aria-label="Close"></button>
                        </div>
                    )}
                    {error ? (
                        <div className="alert alert-danger" role="alert">
                            {error}
                        </div>
                    ) : (
                        <table className="table table-hover table-rounded table-striped border gy-7 gs-7 align-middle    dataTable no-footer">
                            <thead className="text-start text-muted fw-bolder fs-7 text-uppercase  gs-0">
                                <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                                    <th style={{ whiteSpace: 'nowrap' }} onClick={() => handleHeaderClick('is_starred')}>

                                        {sortConfig.key === 'is_starred' && (
                                            <span className={`sorting-icon ${sortConfig.direction === 'asc' ? 'asc' : 'desc'}`}>
                                                <i className={`bi ${sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
                                            </span>
                                        )}
                                    </th>
                                    <th style={{ whiteSpace: 'nowrap' }} onClick={() => handleHeaderClick('id')}>
                                        Id
                                        {sortConfig.key === 'id' && (
                                            <span className={`sorting-icon ${sortConfig.direction === 'asc' ? 'asc' : 'desc'}`}>
                                                <i className={`bi ${sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
                                            </span>
                                        )}
                                    </th>
                                    <th style={{ whiteSpace: 'nowrap' }} onClick={() => handleHeaderClick('title')}>
                                        Title
                                        {sortConfig.key === 'title' && (
                                            <span className={`sorting-icon ${sortConfig.direction === 'asc' ? 'asc' : 'desc'}`}>
                                                <i className={`bi ${sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
                                            </span>
                                        )}
                                    </th>
                                    <th style={{ whiteSpace: 'nowrap' }} onClick={() => handleHeaderClick('docketType')}>
                                        Docket Type
                                        {sortConfig.key === 'docketType' && (
                                            <span className={`sorting-icon ${sortConfig.direction === 'asc' ? 'asc' : 'desc'}`}>
                                                <i className={`bi ${sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
                                            </span>
                                        )}
                                    </th>
                                    <th style={{ whiteSpace: 'nowrap' }} onClick={() => handleHeaderClick('date')}>
                                        Date Modified
                                        {sortConfig.key === 'date' && (
                                            <span className={`sorting-icon ${sortConfig.direction === 'asc' ? 'asc' : 'desc'}`}>
                                                <i className={`bi ${sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
                                            </span>
                                        )}
                                    </th>

                                    <th className="col-1 " style={{ whiteSpace: 'nowrap' }} onClick={() => handleHeaderClick('is_archived')}>
                                        Archive
                                        {sortConfig.key === 'is_archived' && (
                                            <span className={`sorting-icon ${sortConfig.direction === 'asc' ? 'asc' : 'desc'}`}>
                                                <i className={`bi ${sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
                                            </span>
                                        )}
                                    </th>
                                </tr>
                            </thead>
                            <tbody >
                                {filteredDockets.map((docket) => (
                                    <tr key={docket.iddocket}>
                                        <td>
                                            {docket.is_starred === 0 ? (
                                                <button
                                                    className="btn btn-outline-success h-25px w-5px btn-sm d-flex align-items-center justify-content-center"
                                                    onClick={() => handleStarDocket(docket.iddocket)}
                                                >
                                                    <i className="bi bi-star"></i>
                                                </button>
                                            ) : (
                                                <button
                                                    className="btn btn-outline-danger h-25px w-5px btn-sm d-flex align-items-center justify-content-center"
                                                    onClick={() => handleUnstarDocket(docket.iddocket)}
                                                >
                                                    <i className="bi bi-star-fill "></i>
                                                </button>
                                            )}
                                        </td>
                                        <td style={{ whiteSpace: 'nowrap' }}>{docket.id}</td>
                                        <td>{docket.title}</td>
                                        <td style={{ whiteSpace: 'nowrap' }}> {docket.docketType}</td>
                                        <td style={{ whiteSpace: 'nowrap' }}>{formatDate(docket.modifyDate)}</td>

                                        <td >
                                            <div className="d-flex justify-content-center">
                                                {docket.is_archived === 0 ? (
                                                    <button
                                                        className="btn  h-25px w-4px btn-sm d-flex align-items-center justify-content-center"
                                                        onClick={() => handleArchiveDocket(docket.iddocket)}
                                                    >
                                                        <KTIcon iconName='archive' className='fs-2 text-success' />
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="btn h-25px w-4px btn-sm d-flex align-items-center justify-content-center"
                                                        onClick={() => handleUnarchiveDocket(docket.iddocket)}
                                                    >
                                                        <i className="bi bi-archive"></i>
                                                    </button>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div >
        //         </div>
        //     </div>
        // </div>
    );
};

export default Docket;
