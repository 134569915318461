import React, { FC } from 'react'
import Document from './Document'

const DocumentWrapper: FC = () => {
    return (
        <>
            {/* <PageTitle breadcrumbs={[]}>Dockets</PageTitle> */}
            <Document />
        </>
    )
}

export default DocumentWrapper
