import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';


const Calendar = ({ events }) => {
    const [calendarView] = useState('dayGridMonth');
    // const [calendarView, setCalendarView] = useState('dayGridMonth');
    // const [events, setEvents] = useState([]); // Store the calendar events
    const [newEvent, setNewEvent] = useState(null); // Store the new event being added
    const [selectedEvent, setSelectedEvent] = useState(null);


    // Function to handle a click on a calendar day to add a new event
    const handleDateClick = (arg) => {
        setNewEvent({ title: 'New Event', start: arg.dateStr });
    };

    // Function to handle the click of the "Save" button on the event creation form
    const handleSaveEvent = () => {
        if (newEvent) {
            // setEvents([...events, newEvent]); // Add the new event to the events array
            setNewEvent(null); // Clear the new event
        }
    };

    // Function to change the calendar view to "year"


    // Define the event click handler (for editing or deleting events)
    const handleEventClick = (clickInfo) => {
        setSelectedEvent(clickInfo.event); // Store the clicked event
    };
    const handleCloseEventModal = () => {
        setSelectedEvent(null); // Clear the selected event
    };



    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-9">
                    <FullCalendar
                        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                        initialView={calendarView}
                        events={events}
                        editable={true}
                        selectable={true}
                        aspectRatio={2}
                        dateClick={handleDateClick} // Handle clicks on calendar days
                        eventClick={handleEventClick} // Handle clicks on events
                        contentHeight="auto" // Automatically adjust the calendar's height
                        themeSystem="bootstrap" // Apply Bootstrap theme
                        headerToolbar={{
                            left: 'prev,next today',
                            center: 'title',
                            right: 'dayGridYear,dayGridMonth,timeGridWeek,timeGridDay',
                        }}
                        dayMaxEventRows={3}
                        eventBackgroundColor="#007BFF" // Event background color (blue)
                        eventBorderColor="#007BFF" // Event border color (blue)
                        eventDisplay="block"
                        displayEventTime={false}


                    />

                </div>
                <div className="col-md-3">
                    {newEvent && (
                        <div>
                            <h3 className="mb-4">Create New Event</h3>
                            <input
                                type="text"
                                className="form-control mb-3"
                                value={newEvent.title}
                                onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
                            />
                            <button
                                className="btn btn-success"
                                onClick={handleSaveEvent}
                            >
                                Save
                            </button>
                        </div>
                    )}
                </div>
            </div>
            {selectedEvent && (
                <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header " style={{ padding: '20px', margin: '0', fontFamily: 'Google Sans, Roboto, Arial, sans-serif' }}>
                                <h5 className="modal-title" style={{ fontSize: '22px', fontWeight: 400, margin: '0' }}>{selectedEvent.title}</h5>
                                {/* <button
                                    type="button"
                                    className="btn-close"
                                    onClick={handleCloseEventModal}
                                ></button> */}
                            </div>
                            <div className="modal-body">
                                <p style={{ fontFamily: 'Roboto, Arial, sans-serif', fontSize: '16px', fontWeight: 500, padding: '20px', margin: '0', whiteSpace: 'pre-wrap' }}>{selectedEvent.extendedProps.description}</p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-primary mx-auto"
                                    onClick={handleCloseEventModal}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
};

export default Calendar;
