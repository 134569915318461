// @ts-nocheck
import { Column } from 'react-table'
import { OrganizationInfoCell } from './OrganizationInfoCell'
import { OrganizationIndustryCell } from './OrganizationIndustryCell'
import { OrganizationPartnerCell } from './OrganizationPartnerCell'
import { OrganizationStatusCell } from './OrganizationStatusCell'
import { OrganizationActionsCell } from './OrganizationActionsCell'
import { OrganizationSelectionCell } from './OrganizationSelectionCell'
import { OrganizationCustomHeader } from './OrganizationCustomHeader'
import { OrganizationSelectionHeader } from './OrganizationSelectionHeader'
import { Organization } from '../../core/_models'

const OrganizationsColumns: ReadonlyArray<Column<Organization>> = [
  {
    Header: (props) => <OrganizationSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({ ...props }) => <OrganizationSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <OrganizationCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'name',
    Cell: ({ ...props }) => <OrganizationInfoCell Organization={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <OrganizationCustomHeader tableProps={props} title='Primary User ID' className='min-w-125px' />,
    accessor: 'primaryuser',
  },
  {
    Header: (props) => (
      <OrganizationCustomHeader tableProps={props} title='Industry' className='min-w-125px' />
    ),
    id: 'industry',
    Cell: ({ ...props }) => <OrganizationIndustryCell industry={props.data[props.row.index].industry} />,
  },
  {
    Header: (props) => (
      <OrganizationCustomHeader tableProps={props} title='Partner' className='min-w-125px' />
    ),
    id: 'is_partner',
    Cell: ({ ...props }) => <OrganizationPartnerCell is_partner={props.data[props.row.index].is_partner} />,
  },
  {
    Header: (props) => (
      <OrganizationCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'is_active',
    Cell: ({ ...props }) => <OrganizationStatusCell is_active={props.data[props.row.index].is_active} />,
  },
  {
    Header: (props) => (
      <OrganizationCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({ ...props }) => <OrganizationActionsCell id={props.data[props.row.index].id} />,
  },
]

export { OrganizationsColumns }
