//import internal from "stream"
import { ID, Response } from "../../../../../../../_metronic/helpers"
export type Organization = {
  id?: ID;
  name?: string;
  primaryuser?: number;
  website?: string;
  industry?: string;
  address?: string;
  city?: string;
  state?: string;
  region?: string;
  country?: string;
  zip?: string;
  phone?: string;
  language?: string;
  is_deleted?: number;
  is_active?: number;
  start_date?: number;
  end_date?: string;
  is_partner?: number;
  parent_id?: number;
  create_date?: number;
  modified_date?: number;
}

export type OrganizationsQueryResponse = Response<Array<Organization>>

export const initialOrganization: Organization = {
  name: "",
  primaryuser: 0,
  website: "",
  industry: "",
  address: "",
  city: "",
  state: "",
  region: "",
  country: "",
  zip: "",
  phone: "",
  language: "",
  is_deleted: 0,
  is_active: 0,
  start_date: 1695865085000,
  end_date: "0000-00-00 00:00:00",
  is_partner: 0,
  parent_id: 0,
  create_date: 1695865085000,
  modified_date: 1698781947000
}
