import React, { FC } from 'react'
import Admin from './Admin'

const AdminWrapper: FC = () => {
    return (
        <>
            <Admin />
        </>
    )
}

export default AdminWrapper;
