/* eslint-disable react/jsx-no-target-blank */
import { useEffect } from 'react'
import { ILayout, useLayout } from '../../core'

const Footer = () => {
  const { config } = useLayout()
  useEffect(() => {
    updateDOM(config)
  }, [config])
  return (
    <>
      {/* <div className='text-dark order-2 order-md-1'> */}
      {/* <span className='text-muted fw-semibold me-1'>
          {new Date().getFullYear().toString()}&copy;
        </span> */}
      <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
        <li className='menu-item'>
          <a
            href='https://lexim.ai/'
            target='_blank'
            className='menu-link px-4'
          >
            LEXIM.AI
          </a>
        </li>
        <li className='menu-item'>
          <a
            href='https://lexim.ai/'
            target='_blank'
            className='menu-link px-4'
          >
            Blogs
          </a>
        </li>
        <li className='menu-item'>
          <a
            href='https://lexim.ai/'
            target='_blank'
            className='menu-link px-4'
          >
            Careers
          </a>
        </li>
        <li className='menu-item'>
          <a
            href='https://lexim.ai/'
            target='_blank'
            className='menu-link px-4'
          >
            Help
          </a>
        </li>
        <li className='menu-item'>
          <a
            href='https://lexim.ai/'
            target='_blank'
            className='menu-link px-4'
          >
            Support
          </a>
        </li>
        <li className='menu-item'>
          <a
            href='https://lexim.ai/'
            target='_blank'
            className='menu-link px-4'
          >
            Feedback
          </a>
        </li>
      </ul>
      {/* </div> */}

      <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
        <li className='menu-item'>
          <a href='https://lexim.ai/' target='_blank' className='menu-link px-4'>
            Terms of Service
          </a>
        </li>
        <li className='menu-item'>
          <a href='https://lexim.ai/' target='_blank' className='menu-link px-4'>
            Privacy
          </a>
        </li>
        <li className='menu-item'>
          <a href='https://lexim.ai/' target='_blank' className='menu-link px-4'>
            Cookies
          </a>
        </li>
        <li className='menu-item'>
          <p className='menu-link px-4'>
            Copyright © 2023. LEXIM AI, INC.
          </p>
        </li>

      </ul>
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export { Footer }
