import React, { FC } from 'react'
import Comments from './Comments'

const CommentsWrapper: FC = () => {
    return (
        <>
            {/* <PageTitle breadcrumbs={[]}>Dockets</PageTitle> */}
            <Comments />
        </>
    )
}

export default CommentsWrapper
