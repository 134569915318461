import { FC } from 'react'

type Props = {
  is_active?: number
}

const OrganizationStatusCell: FC<Props> = ({ is_active }) => (
  <div className={`badge ${is_active === 1 ? 'badge-success' : 'badge-light'} fw-bolder`}>
    {is_active === 1 ? 'Active' : 'Inactive'}
  </div>
)

export { OrganizationStatusCell }
