import React, { useEffect } from 'react';
import axios from 'axios';
import ViewSDKClient from './ViewSDKClient';

function FullWindow({ documentId }) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        const fetchAndDisplayPDF = async () => {
            try {

                // Fetch the PDF data from the backend
                const response = await axios.get(`/reg/v1/document/veiwtxt/${documentId}`, { responseType: 'arraybuffer' });
                console.log("backend response", response)
                const fileData = new Uint8Array(response.data);
                const filePromise = Promise.resolve(fileData);

                // Create an instance of ViewSDKClient and configure the viewer
                const viewSDKClient = new ViewSDKClient();
                viewSDKClient.ready().then(() => {
                    viewSDKClient.previewFileUsingFilePromise("pdf-div", filePromise, 'document', { embedMode: "FULL_WINDOW", defaultViewMode: "FIT_PAGE", showAnnotationTools: true, showDownloadPDF: true });
                });
            } catch (error) {
                console.error('Error fetching PDF:', error);
            }
        };

        fetchAndDisplayPDF();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <div id="pdf-div" className="full-window-div" style={{ position: 'absolute', width: '80%', height: '85%' }}>
                {/* Add CSS styles for full window display */}
            </div>
        </div>
    );
}

export default FullWindow;
