import React, { FC } from 'react'
import Docket from './Dockets'

const DocketWrapper: FC = () => {
    return (
        <>

            <Docket />
        </>
    )
}

export default DocketWrapper
