import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios'; // Import axios
import { KTIcon } from '../../../_metronic/helpers';
import DocumentFilter from './DocumentFilter';
import { useNavigate } from 'react-router-dom';







const Document = () => {
    // Define your docket details or fetch them from an API

    const selectedDocket = useSelector((state) => state.dockets.selectedDocket);
    const [documents, setDocuments] = useState([]);
    // const [showExportOptions, setShowExportOptions] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [filteredDocuments, setFilteredDocuments] = useState([]);
    const [filterDocument, setFilterDocument] = useState('');
    const [filterStatus, setFilterStatus] = useState('');
    const [filterStartDate, setFilterStartDate] = useState('');
    const [filterEndDate, setFilterEndDate] = useState('');
    const [filterStarred, setFilterStarred] = useState(false);
    const [searchInput, setSearchInput] = useState('');


    useEffect(() => {
        if (selectedDocket && selectedDocket.iddocket) {
            // Make a GET request to fetch documents data only if a docket is selected
            axios.get(`/reg/v1/document/3/${selectedDocket.iddocket}`)
                .then((response) => {
                    console.log(selectedDocket.iddocket);
                    setDocuments(response.data);
                    setFilteredDocuments(response.data)
                    setLoading(false);
                    setError(null);
                })
                .catch((error) => {
                    console.log(selectedDocket.iddocket);
                    setDocuments([]);
                    setFilteredDocuments([]);
                    setError(error);
                    setLoading(false);
                });
        } else {
            // No docket selected, clear the documents and error
            setDocuments([]);
            setFilteredDocuments([]);
            setError(null);
            setLoading(false);
        }
    }, [selectedDocket]);

    useEffect(() => {
        // If the search input is empty, set filteredDockets to all dockets
        if (searchInput.trim() === '') {
            setFilteredDocuments(documents);
        } else {
            // Otherwise, filter dockets based on the search input
            const searchTerm = searchInput.toLowerCase();
            const filtered = documents.filter((document) => {
                return (
                    // Include more fields if needed for the search
                    document.id.toLowerCase().includes(searchTerm) ||
                    document.title.toLowerCase().includes(searchTerm) ||
                    document.documentType.toLowerCase().includes(searchTerm)
                );
            });
            setFilteredDocuments(filtered);
        }
    }, [searchInput, documents]);

    const applyFilters = () => {
        let filtered = documents; // Start with the original list


        // Apply filters one by one
        if (filterDocument) {
            filtered = filtered.filter((document) =>
                document.id.toLowerCase().includes(filterDocument.toLowerCase())
            );
        }
        if (filterStatus) {
            filtered = filtered.filter((document) => document.documentType === filterStatus);
        }
        if (filterStartDate) {
            const startTimestamp = new Date(filterStartDate).getTime(); // Default to 0 if filterStartDate is empty or null

            filtered = filtered.filter((document) => {
                const modifyDateTimestamp = new Date(document.commentStartDate).getTime();
                return modifyDateTimestamp >= startTimestamp
            }
            );
        }
        if (filterEndDate) {
            const endTimestamp = new Date(filterEndDate).getTime(); // Default to max value if filterEndDate is empty or null

            filtered = filtered.filter((document) => {
                const modifyDateTimestamp = new Date(document.commentEndDate).getTime();
                return modifyDateTimestamp <= endTimestamp
            });
        }
        if (filterStarred !== false) {
            filtered = filtered.filter((document) => document.is_starred === 1);
        }

        setFilteredDocuments(filtered);
    };

    const handleApplyFilters = () => {
        applyFilters();
    };
    const handleResetFilters = () => {
        // Clear filter state variables
        setFilterDocument('');
        setFilterStatus('');
        setFilterStartDate('');
        setFilterEndDate('');
        setFilterStarred(false);
        setFilteredDocuments(documents);
    };


    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString();
    };
    // const toggleExportOptions = () => {
    //     setShowExportOptions(!showExportOptions);
    // };

    const handleStarDocket = (id) => {
        const endpoint = `/reg/v1/document/star/3/${id}`;

        // Send a POST request to subscribe or unsubscribe
        axios.get(endpoint)
            .then((response) => {
                // console.log(response.data);
                axios.get(`/reg/v1/document/3/${selectedDocket.iddocket}`)
                    .then((response) => {
                        console.log(selectedDocket.iddocket);
                        setDocuments(response.data);
                        setLoading(false);
                        setError(null);
                    })
                    .catch((error) => {
                        console.log(selectedDocket.iddocket);
                        setDocuments([]);
                        setError(error);
                        setLoading(false);
                    });


                // Handle a successful subscription or unsubscription
                // You can update the UI or show a success message
                // For example, you can refresh the list of dockets after subscription
                // Define a function to fetch dockets
            })
            .catch((error) => {
                // Handle errors, e.g., show an error message
                // console.log(error);
                console.error('Error starring/unstarring', error);
            });


    }
    const handleViewButtonClick = (documentId) => {
        navigate('/viewpdf', { state: { id: documentId } })

    };





    return (

        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>

            <div className='d-flex flex-column flex-column-fluid'>



                <div className="d-flex flex-row-fluid flex-center px-5 mb-10">
                    <div className="flex-column flex-row-fluid  flex-center ">
                        {selectedDocket.iddocket ? (
                            <>
                                <h5 className=" text-dark d-md-block text-wrap"> Docket:{'  '} {selectedDocket.id} {' - '} {selectedDocket.title}</h5>

                            </>
                        ) : (
                            <div>Please select a docket to view documents!</div>
                        )}
                    </div>

                </div>

                <div className="d-flex flex-column-auto  align-items-center mt-10 px-5 ">
                    <div className="d-flex flex-column-auto  align-items-center  ">
                        <div className="d-flex align-items-center position-relative my-1  ">
                            <KTIcon iconName='magnifier' className='fs-2 position-absolute ms-6' />
                            <input
                                type="search"
                                id="form1"
                                className="form-control form-control-solid w-250px ps-14 "
                                placeholder="Search documents"
                                aria-label="Search"
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                                maxLength={200}
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-column-auto  mx-4    ">
                        <button
                            type='button'
                            className='btn  btn-light-primary  btn-active-light  d-flex align-items-center justify-content-center '
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'
                        >

                            <KTIcon iconName='filter' className='fs-2' /> Filter

                        </button>
                        <DocumentFilter
                            filterDocument={filterDocument}
                            setFilterDocument={setFilterDocument}
                            filterStatus={filterStatus}
                            setFilterStatus={setFilterStatus}
                            filterStartDate={filterStartDate}
                            setFilterStartDate={setFilterStartDate}
                            filterEndDate={filterEndDate}
                            setFilterEndDate={setFilterEndDate}
                            filterStarred={filterStarred}
                            setFilterStarred={setFilterStarred}
                            onApplyFilters={handleApplyFilters}
                            onResetFilters={handleResetFilters}
                        // documents={dockets}
                        />
                    </div>
                    <div className="d-flex flex-column-auto  flex-center">
                        <button type="button"
                            className="btn btn-primary   d-flex align-items-center justify-content-center "
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_1"
                        >
                            <KTIcon iconName='plus' className='fs-2 ' />Add document
                        </button>
                        <div className="modal fade" tabIndex={-1} id="kt_modal_1">
                            <div className="modal-dialog modal-dialog-centered modal-lg" >
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Upload a File</h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label htmlFor="fileInput" className="form-label">
                                                    Select File
                                                </label>
                                                <div className="input-group mb-3">
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        id="fileInput"
                                                        aria-describedby="fileInputHelp"
                                                    />

                                                </div>
                                                <small id="fileInputHelp" className="form-text text-muted">
                                                    Select a file from your computer.
                                                </small>
                                            </div>
                                            <div className="col-md-6">
                                                <label className="form-label">Or Drag and Drop Here</label>
                                                <div className="border border-primary rounded p-5 text-center">
                                                    <i className="bi bi-cloud-upload fs-1 text-primary mb-3"></i>
                                                    <p className="mb-0">Drag & Drop your files here</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                            Cancel
                                        </button>
                                        <button type="button" className="btn btn-primary">
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>




                <div className="d-flex flex-column flex-row-fluid mt-2 px-5">
                    <table className="table">
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan="7">Loading...</td>
                                </tr>
                            ) : error ? (
                                <tr>
                                    <td colSpan="7">
                                        {error.response && error.response.status === 404 ? (
                                            <div className="alert alert-danger" role="alert" >No documents are available for the selected docket.</div>
                                        ) : (
                                            <div className="alert alert-danger" role="alert">Error: {error.message}</div>
                                        )}
                                    </td>
                                </tr>
                            ) : (
                                filteredDocuments.map((document) => (
                                    <tr key={document.iddocument}>

                                        <td colSpan="6">
                                            <div className="card card-custom">





                                                <div className="card-header bg-light bg-gradient  ">



                                                    <div className="card-title m-0 align-items-center justify-content-center  ">
                                                        {document.is_starred === null || document.is_starred === 0 ? (
                                                            <button
                                                                className=" btn  h-25px w-5px btn-sm d-flex align-items-center justify-content-center "
                                                                onClick={() => handleStarDocket(document.iddocument)}
                                                            >
                                                                <i class="bi bi-star"></i>
                                                            </button>
                                                        ) : (
                                                            <button
                                                                className="btn  h-25px w-5px btn-sm d-flex align-items-center justify-content-center  "
                                                                onClick={() => handleStarDocket(document.iddocument)}
                                                            >
                                                                <i class="bi bi-star-fill "></i>
                                                            </button>
                                                        )}
                                                        <h5 className="card-title m-0 ">Document ID:{' '}{document.id}</h5>
                                                    </div>


                                                    <div className="card-title ">
                                                        {/* Download button */}

                                                        <div className="d-flex justify-content-end align-items-center ">
                                                            {/* Download button */}
                                                            <button className="btn h-30px w-4px btn-sm d-flex align-items-center justify-content-center" onClick={() => handleViewButtonClick(document.iddocument)}>
                                                                <KTIcon iconName='eye' className='fs-1 text-primary' />
                                                            </button>

                                                            <a href={document.fileUrl1} className="btn mx-3   h-30px w-4px  btn-sm d-flex align-items-center justify-content-center"><KTIcon iconName='file-down' className='fs-1 text-primary' /></a>
                                                            {/* Export button */}

                                                            <div className="dropdown d-flex align-items-center justify-content-center">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm h-30px btn-primary dropdown-toggle d-flex align-items-center justify-content-center"
                                                                    id="exportDropdown"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-expanded="false"
                                                                >
                                                                    Export
                                                                </button>
                                                                <ul className="dropdown-menu" aria-labelledby="exportDropdown">
                                                                    <li>
                                                                        <button type="button" className="dropdown-item">
                                                                            Export to Google Docs
                                                                        </button>
                                                                    </li>
                                                                    <li>
                                                                        <button type="button" className="dropdown-item">
                                                                            Export to MS Word
                                                                        </button>
                                                                    </li>
                                                                    <li>
                                                                        <button type="button" className="dropdown-item">
                                                                            Export to Google Sheets
                                                                        </button>
                                                                    </li>

                                                                </ul>
                                                            </div>
                                                        </div>
                                                        {/* Export button */}


                                                    </div>

                                                </div>
                                                <div className="card-body card-scroll  ">
                                                    {/* Document content */}
                                                    <div className="mb-3">
                                                        <div className="d-flex ">
                                                            <span className="fw-bold">Title:</span>
                                                            <span className="fw-normal mx-2">{document.title}</span>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <div className="flex">
                                                            <span className="fw-bold">Document Type:</span>
                                                            <span className="fw-normal mx-2">{document.documentType}</span>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <div className="flex">
                                                            <span className="fw-bold">Comment Start Date:</span>
                                                            <span className="fw-normal mx-2">{formatDate(document.commentStartDate)} {''}</span>
                                                            <span className="fw-bold">Comment End Date:</span>
                                                            <span className="fw-normal mx-2 ">{formatDate(document.commentEndDate)}</span>
                                                        </div>
                                                    </div>

                                                    <div className="mb-3">
                                                        <div className="flex ">
                                                            <span className="fw-bold">Posted Date:</span>
                                                            <span className="fw-normal mx-2">{document.postedDate}</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>

                </div>
            </div>


        </div>



    );
};

export default Document;






