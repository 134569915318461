import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedDocket } from "../../../redux/dockets/actions";



const MyDockets = () => {

    
    const [mydockets, setMydockets] = useState([]);
    const selectedDocket = useSelector((state) => state.dockets.selectedDocket);

    const selectedAgency = useSelector(state => state.agencies.selectedAgency);
    const dispatch = useDispatch();

    useEffect(() => {
        // Make an API call to fetch My Dockets data
        axios.get(`/reg/v1/docket/user/3/${selectedAgency}`).then((response) => {
            // Assuming the API response contains an array of dockets
            const { data } = response;
            
            if (data.length > 0) {
                setMydockets(data);
                // If there are dockets, set the first docket as the default in Redux
                const firstDocket = data[0];
                dispatch(setSelectedDocket({
                    id: firstDocket.id,
                    title: firstDocket.title,
                    iddocket: firstDocket.iddocket,
                }));
            }
        }).catch((error) => {
            console.error("Error fetching My Dockets:", error);
            setMydockets([]);
        });
    }, [selectedAgency, dispatch]);

    const handleDocketChange = (e) => {
        // Extract the selected docket ID and title from the option value
        const [id, title, iddocket] = e.target.value.split('|');
        // Dispatch the selected docket as an object with ID and title
        dispatch(setSelectedDocket({ id, title, iddocket }));
    };


    return (
        <div className="d-flex align-items-center">
            <span className="fs-7 fw-bold text-black-700 flex-shrink-0 pe-4 d-none d-md-block">
                My Dockets
            </span>
            <select
                className="form-select form-select-sm w-500px form-select-solid me-6"
                data-control="select2"
                data-placeholder="Select Docket"
                value={`${selectedDocket.id}|${selectedDocket.title}|${selectedDocket.iddocket}`}
                onChange={handleDocketChange}
            >
                {/* <option value="">Select Docket</option> */}
                {Array.isArray(mydockets) && mydockets.length > 0 ? (
                    mydockets.map((docket) => (
                        <option key={docket.id} value={`${docket.id}|${docket.title}|${docket.iddocket}`}>
                            {docket.title.length > 100
                                ? docket.title.substring(0, 100) + "..."
                                : docket.title}
                        </option>
                    ))
                ) : (
                    <option value="" disabled>
                        No Dockets Found for Selected Agency
                    </option>
                )}
            </select>
        </div>
    );
};


export default MyDockets;