import { Navigate, Routes, Route } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { Organizations } from './Organizations'
import { ProfileHeader2 } from './ProfileHeader'
import { Settings } from './Settings'
import { Users } from './Users'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'SuperAdmin',
    path: '/super-admin',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProfilePage = () => (
  <>
    <ProfileHeader2 />
    <Routes>
      {/* <Route
      element={
        <>
          <ProfileHeader2 />
          <Outlet />
        </>
      }
    > */}
      <Route
        path='organizations'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}> Organizations</PageTitle>
            <Organizations />
          </>
        }
      />
      <Route
        path='settings'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}> Settings</PageTitle>
            <Settings />
          </>
        }
      />
      <Route
        path='users'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}> Users</PageTitle>
            <Users />
          </>
        }
      />
      <Route
        path='feedback'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}> Feedback</PageTitle>
            <h1>Feedback</h1>
          </>
        }
      />
      <Route
        path='support'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}> Support</PageTitle>
            <h1>Support</h1>
          </>
        }
      />
      <Route
        path='billing'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}> Billing</PageTitle>
            <h1>Billing</h1>
          </>
        }
      />

      <Route index element={<Navigate to='/super-admin/organizations' />} />
      {/* </Route> */}
    </Routes>
  </>
)

export default ProfilePage
