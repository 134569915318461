import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import PdfViewer from './PdfViewer';

const PdfWrapper: FC = () => {
    const location = useLocation();
    const documentId = location.state ? location.state.id : null;
console.log("in pdf wrapper",documentId)
    return (
        <>
            {/* Your component content */}
            <PdfViewer documentId={documentId} />
        </>
    );
}

export default PdfWrapper;
// added