import axios, { AxiosResponse } from 'axios'
import { ID, Response } from "../../../../../../../_metronic/helpers"
import { Organization, OrganizationsQueryResponse } from './_models'

// const API_URL = process.env.REACT_APP_THEME_API_URL
// const Organization_URL = `reg/v1/admin/organization`
const GET_ORGANIZATIONS_URL = `/reg/v1/superadmin/org`

const getOrganizations = (query: string): Promise<OrganizationsQueryResponse> => {
  console.log("query", query);
  const searchParam = new URLSearchParams(query).get("search");
  const filterParamActive = new URLSearchParams(query).get("filter_isactive");
  const filterParamPartner = new URLSearchParams(query).get("filter_ispartner");
  const sortParam = new URLSearchParams(query).get("sort");
  const orderParam = new URLSearchParams(query).get("order");

  console.log("Searchparam", searchParam);
  console.log("filterparamActive", filterParamActive);
  console.log("filterparamPartner", filterParamPartner);
  console.log("sortParam", sortParam);
  console.log("orderParam", orderParam);

  const filterParamActiveAsNumber = filterParamActive ? parseInt(filterParamActive, 10) : null;
  const filterParamPartnerAsNumber = filterParamPartner ? parseInt(filterParamPartner, 10) : null;

  return axios
    .get(`${GET_ORGANIZATIONS_URL}?${query}`)
    .then((d: AxiosResponse<OrganizationsQueryResponse>) => {
      if (!searchParam && filterParamActiveAsNumber === null && filterParamPartnerAsNumber === null && sortParam === null && orderParam === null) {
        // If searchParam is not provided, return the entire data without filtering
        const data = d.data;
        return data;
      }
      const data = d.data as Organization[];
      console.log("Response data:", data); // Log the data
      let filteredData = data;

      if (filterParamActiveAsNumber !== null) {
        // Filter the data based on filterParam if it's not null
        filteredData = filteredData.filter((organization) => organization.is_active === filterParamActiveAsNumber);
      }
      if (filterParamPartnerAsNumber !== null) {
        // Filter the data based on filterParam if it's not null
        filteredData = filteredData.filter((organization) => organization.is_partner === filterParamPartnerAsNumber);
      }

      if (searchParam) {
        // Filter the data based on searchParam if it's not null
        filteredData = filteredData.filter((organization) => {
          return (
            organization.name?.toLowerCase().includes(searchParam) ||
            organization.website?.toLowerCase().includes(searchParam) ||
            organization.industry?.toLowerCase().includes(searchParam)
          );
        });
      }

      if (sortParam && orderParam) {
        // Sort the data based on the sortParam and orderParam
        filteredData = filteredData.sort((a, b) => {
          const aValue = a[sortParam];
          const bValue = b[sortParam];

          if (orderParam === 'asc') {
            if (typeof aValue === 'string' && typeof bValue === 'string') {
              // Case-insensitive sorting for strings in ascending order
              const aValueLower = aValue.toLowerCase();
              const bValueLower = bValue.toLowerCase();
              return aValueLower < bValueLower ? -1 : 1;
            } else {
              // Regular sorting for non-string values in ascending order
              return aValue < bValue ? -1 : 1;
            }
          } else if (orderParam === 'desc') {
            if (typeof aValue === 'string' && typeof bValue === 'string') {
              // Case-insensitive sorting for strings in descending order
              const aValueLower = aValue.toLowerCase();
              const bValueLower = bValue.toLowerCase();
              return aValueLower > bValueLower ? -1 : 1;
            } else {
              // Regular sorting for non-string values in descending order
              return aValue > bValue ? -1 : 1;
            }
          }
          return 0;
        });
      }

      console.log(filteredData);

      return {
        data: filteredData, // Wrap the filtered data in a `data` property
      };



    });
}

const getOrganizationById = (id: ID): Promise<Organization | undefined> => {
  return axios
    .get(`/reg/v1/superadmin/organization/${id}`)
    .then((response: AxiosResponse<Response<Organization>>) => {
      const data = response.data;
      console.log('Organization Data:', data[0]); // Log the response data
      return data[0];
    })
}

const createOrganization = (organization: Organization): Promise<Organization | undefined> => {
  console.log("error values", organization);

  const data = {
    name: organization.name,
    primaryuser: organization.primaryuser,
    website: organization.website,
    industry: organization.industry,
    address: " ",
    city: " ",
    state: " ",
    region: " ",
    country: " ",
    zip: " ",
    phone: " ",
    language: " ",
    is_partner: organization.is_partner,
    end_date: '0000-00-00 00:00:00',
    parent_id: 0,
  };

  const jsonData = JSON.stringify(data);
  console.log("jsonDATA", jsonData);
  return axios
    .post(`/reg/v1/superadmin/organization/0`, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    }) // Send 'data' as the payload
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        const updatedorganization = response.data;
        console.log('Organization Added:', updatedorganization);
        return updatedorganization;
      } else {
        // Handle the case where the response status is not 200 (e.g., handle errors)
        console.error('Error updating organization: HTTP Status', response.status);
        throw new Error('Update failed');
      }
    })
    .then((response: Response<Organization>) => response.data)
    .catch((error) => {
      console.error('Error updating organization:', error);
      throw error; // Re-throw the error for handling in your component
    });
}

const updateOrganization = (organization: Organization): Promise<Organization | undefined> => {
  console.log("org values", organization);
  const data = {
    name: organization.name,
    primaryuser: organization.primaryuser,
    website: organization.website,
    industry: organization.industry,
    address: organization.address,
    city: organization.city,
    state: organization.state,
    region: organization.region,
    country: organization.country,
    zip: organization.zip,
    phone: organization.phone,
    language: organization.language,
    is_partner: organization.is_partner,
    end_date: organization.end_date,
    parent_id: organization.parent_id,
  };
  console.log("data", data);
  const jsonData = JSON.stringify(data);
  console.log("jsondata", jsonData);
  return axios
    .patch(`/reg/v1/superadmin/organization/${organization.id}`, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    }) // Send 'data' as the payload
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        const updatedOrg = response.data;
        console.log('Updated Organization Data:', updatedOrg);
        return updatedOrg;
      } else {
        // Handle the case where the response status is not 200 (e.g., handle errors)
        console.error('Error updating organization: HTTP Status', response.status);
        throw new Error('Update failed');
      }
    })
    .then((response: Response<Organization>) => response.data)
    .catch((error) => {
      console.error('Error updating user:', error);
      throw error; // Re-throw the error for handling in your component
    });
}

const deleteOrganization = (OrganizationId: ID): Promise<void> => {
  return axios.get(`/reg/v1/superadmin//organization/deactivate/${OrganizationId}`).then((response) => { console.log(response) })
}

const deleteSelectedOrganizations = (OrganizationIds: Array<ID>): Promise<void> => {
  const deletePromises = OrganizationIds.map((organizationId) => {
    return deleteOrganization(organizationId);
  });

  // Use Promise.all to wait for all delete requests to complete
  return Promise.all(deletePromises)
    .then((results) => {
      // All delete requests have completed
      console.log("All selected organizations have been deleted.", results);
    })
    .catch((error) => {
      // Handle any errors that may occur during the delete requests
      console.error("Error deleting selected organizations:", error);
    });
}

export { getOrganizations, deleteOrganization, deleteSelectedOrganizations, getOrganizationById, createOrganization, updateOrganization }
