import { FC } from 'react'

type Props = {
  adminnotes?: boolean
}

const UserTwoStepsCell: FC<Props> = ({ adminnotes }) => (
  <> {adminnotes && <div className='badge badge-light fw-bolder'>{adminnotes}</div>}</>
)

export { UserTwoStepsCell }
