import { FC } from 'react'

type Props = {
    is_locked?: number
}

const UserLockedCell: FC<Props> = ({ is_locked }) => (
    <div className={`badge ${is_locked === 1 ? 'badge-danger' : 'badge-success'} fw-bolder`}>
        {is_locked === 1 ? 'Locked' : 'Not locked'}
    </div>)

export { UserLockedCell }
