import { SET_SELECTED_DOCKET } from './types';

const initialState = {
    selectedDocket: {
        id: '', // Initially empty
        title: '', // Initially empty
        iddocket: null,
    },
};

const docketsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_DOCKET:
            return { ...state, selectedDocket: action.payload };
        default:
            return state;
    }
};

export default docketsReducer;