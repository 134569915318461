/* eslint-disable jsx-a11y/anchor-is-valid */
import {Fragment} from 'react'


type Props = {
  className: string
}

const rows: Array<{description: string}> = [
  {description: 'Dockets: 12 (8 rule making, 4 non rule making)'},
  {description: 'Documents: 32'},
  {description: 'Comments: 134 (100 anonymous, 28 organizations, 6 industries)'},
]

const ListsWidget26 = ({className}: Props) => (
  <div className={`card card-flush ${className} bg-primary`}>
    <div className='card-header pt-5'>
      <h3 className='card-title text-gray-800 fw-bold'>Insights</h3>
      <div className='card-toolbar'></div>
    </div>
    <div className='card-body pt-1'>
      {rows.map((row, index) => (
        <Fragment key={`lw26-rows-${index}`}>
          <div className='d-flex flex-stack'>
            <a href='#' className='text-white fw-semibold fs-6 me-2'>
              {row.description}
            </a>
            
          </div>
          {rows.length - 1 > index && <div className='separator separator-dashed my-2' />}
        </Fragment>
      ))}
    </div>
  </div>
)
export {ListsWidget26}
