import React from 'react'

import { Setting } from './setting/Setting'

export function Settings() {
    return (
        
            <Setting />
        
    )
}