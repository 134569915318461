import { ID, Response } from "../../../../../../../_metronic/helpers"
export type User = {
  id?: ID;
  name?: string;
  first_name?: string;
  last_name?: string;
  title?: string;
  avatar?: string;
  email?: string;
  position?: string;
  role?: string;
  last_login?: string;
  two_steps?: boolean;
  joined_day?: string;
  is_active?: number;
  is_locked?: number;
  online?: boolean;
  initials?: {
    label: string;
    state: string;
  };
  adminnotes?: null;
  is_registered?: number;
  is_deleted?: number;
  is_inrecovery?: number;
  suffix?: string;
  phone?: string;
}

export type UsersQueryResponse = Response<Array<User>>

export const initialUser: User = {
  avatar: 'avatars/300-6.jpg',
  position: 'Art Director',
  role: 'Administrator',
  name: '',
  first_name: '',
  last_name: '',
  email: '',
  title: '', // Add the 'title' property with an empty string or default value
  last_login: '', // Add the 'last_login' property with an empty string or default value
  two_steps: false, // Add the 'two_steps' property with a default boolean value
  joined_day: '',
  is_active: 0,
  is_locked: 0, // Add the 'joined_day' property with an empty string or default value
  online: false, // Add the 'online' property with a default boolean value
  initials: {
    label: '',
    state: '',
  },
  adminnotes: null,
  is_registered: 0,
  is_deleted: 0,
  is_inrecovery: 0,
  suffix: "None",
  phone: ""
}
