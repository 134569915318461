import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Calendar from './Calendar';

// ... (import statements)

const CalendarPage = () => {
    // Define docket 
    const selectedDocket = useSelector((state) => state.dockets.selectedDocket);
    const [userEvents, setUserEvents] = useState([]);
    const [docketEvents, setDocketEvents] = useState([]);


    // fetching dockets asynchronously
    useEffect(() => {
        axios.get(`/reg/v1/calendar/user/3`) // Replace this with the actual API endpoint
            .then((userResponse) => {
                const formattedUserEvents = userResponse.data.flatMap((event) => [
                    {
                        title: `User Event ${event.id}`,
                        start: new Date(event.commentStartDate),

                        description: `User event for Document ID ${event.id}\n ${new Date(event.commentStartDate)}-${new Date(event.commentEndDate)}`,
                        backgroundColor: 'green',
                    },
                    {
                        title: `User Event ${event.id}`,

                        start: new Date(event.commentEndDate),
                        description: `User event for Document ID ${event.id}\n ${new Date(event.commentStartDate)}-${new Date(event.commentEndDate)}`,
                        backgroundColor: 'red',
                    },

                ]);

                setUserEvents(formattedUserEvents);
            })
            .catch((userError) => {
                console.error('Error fetching user events:', userError);
                setUserEvents([]); // Clear events if user events fail
            });
    }, []);

    useEffect(() => {
        if (selectedDocket && selectedDocket.iddocket) {
            axios.get(`/reg/v1/calendar/docket/${selectedDocket.iddocket}`)
                .then((docketResponse) => {
                    const formattedDocketEvents = docketResponse.data.flatMap((event) => [
                        {
                            title: `Comment start for document ${event.id} of this docket ${event.docketId}`,
                            start: new Date(event.commentStartDate),
                            description: `Comment starts: ${new Date(event.commentStartDate)}\nComment due: ${new Date(event.commentEndDate)}\nDocument ID: ${event.id}\nDOcket ID: ${event.docketId}`,
                            backgroundColor: 'green',
                        },
                        {
                            title: `Comment Due for document ${event.id} of this docket ${event.docketId}`,
                            start: new Date(event.commentEndDate),
                            description: `Comment date for the document ${event.id} of the docket ${event.docketId} starts on ${new Date(event.commentStartDate)}\n and ends by ${new Date(event.commentEndDate)}\n`,
                            backgroundColor: 'red',
                        },
                    ]);

                    setDocketEvents(formattedDocketEvents);


                })
                .catch((docketError) => {
                    console.error('Error fetching docket events:', docketError);
                    setDocketEvents([]);
                });
        } else {
            // No docket selected, only user events are displayed
            setDocketEvents([]);
        }
    }, [selectedDocket]);







    return (
        // <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
        //     <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
        //         {/* <HeaderWrapper /> */}
        //         <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
        //             {/* <Sidebar /> */}
        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            <div className='d-flex flex-column flex-column-fluid'>
                <div className="d-flex flex-column-auto h-70px flex-center p-4">
                    <div className="d-flex flex-row-fluid flex-center text-center">
                        {/* toolbar */}
                    </div>
                </div>
                <div className="d-flex flex-column flex-row-fluid p-2">
                    {/* mainpage */}

                    <Calendar events={[...userEvents, ...docketEvents]} />
                </div>
            </div>
        </div>
        //         </div>
        //     </div>
        // </div>
    );
};

export default CalendarPage;
