import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import CalendarPage from '../pages/CalendarPage/CalendarPage'
import DocketWrapper from '../pages/Docket/DocketWrapper'
import DocumentWrapper from '../pages/Document/Documentwrapper'
import CommentsWrapper from '../pages/Comments/CommentsWrapper'
import SubscriptionsWrapper from '../pages/MySubsciptions/SubscriptionsWrapper'
import PdfWrapper from '../pages/PDFViewer/PdfWrapper'
import AdminWrapper from '../pages/Admin/AdminWrapper'
import SuperAdminWrapper from '../pages/SuperAdmin/SuperAdminWrapper'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))

  return (
    <Routes>

      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />

        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='/docket' element={<DocketWrapper />} />
        <Route path='/calendar' element={<CalendarPage />} />
        <Route path='/documents' element={<DocumentWrapper />} />
        <Route path='/comments' element={<CommentsWrapper />} />
        <Route path='/subscriptions' element={<SubscriptionsWrapper />} />
        <Route path='/viewpdf' element={<PdfWrapper />} />
        <Route path='/admin/*' element={<AdminWrapper />} />
        <Route path='/super-admin/*' element={<SuperAdminWrapper />} />

        {/* Lazy Modules */}
       
        <Route path='*' element={<Navigate to='/error/404' />} />
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
