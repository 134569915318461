import axios, { AxiosResponse } from 'axios'
import { ID, Response } from "../../../../../../../_metronic/helpers"
import { User, UsersQueryResponse } from './_models'

// const API_URL = process.env.REACT_APP_THEME_API_URL

const USER_URL = `/reg/v1/admin/user`
const GET_USERS_URL = `/reg/v1/admin/orgusers/`; // Update the URL
let orgid = '1';

const getUsers = (query: string): Promise<UsersQueryResponse> => {
  console.log("query", query);
  const searchParam = new URLSearchParams(query).get("search");
  const filterParamActive = new URLSearchParams(query).get("filter_isactive");
  const filterParamLocked = new URLSearchParams(query).get("filter_locked");
  const sortParam = new URLSearchParams(query).get("sort");
  const orderParam = new URLSearchParams(query).get("order");
  const orgParam = new URLSearchParams(query).get("filter_org");
  console.log("Searchparam", searchParam);
  console.log("filterparamActive", filterParamActive);
  console.log("filterparamLocked", filterParamLocked);
  console.log("sortParam", sortParam);
  console.log("orderParam", orderParam);
  console.log("filterparamOrg", orgParam);
  if (orgParam !== null) {
    orgid = orgParam;
  }
  const filterParamAsNumber = filterParamActive ? parseInt(filterParamActive, 10) : null;
  const filterParamLockedAsNumber = filterParamLocked ? parseInt(filterParamLocked, 10) : null;

  return axios
    .get(`${GET_USERS_URL}/${orgid}?${query}`) // Append the query string if needed
    .then((d: AxiosResponse<UsersQueryResponse>) => {
      if (!searchParam && filterParamAsNumber === null && filterParamLockedAsNumber === null && sortParam === null && orderParam === null) {
        // If searchParam is not provided, return the entire data without filtering
        const data = d.data;
        return data;
      }
      const data = d.data as User[];
      console.log("Response data:", data); // Log the data
      let filteredData = data;

      if (filterParamAsNumber !== null) {
        // Filter the data based on filterParam if it's not null
        filteredData = filteredData.filter((user) => user.is_active === filterParamAsNumber);
      }
      if (filterParamLockedAsNumber !== null) {
        // Filter the data based on filterParam if it's not null
        filteredData = filteredData.filter((user) => user.is_locked === filterParamLockedAsNumber);
      }

      if (searchParam) {
        // Filter the data based on searchParam if it's not null
        filteredData = filteredData.filter((user) => {
          return (
            user.first_name?.toLowerCase().includes(searchParam) ||
            user.last_name?.toLowerCase().includes(searchParam) ||
            user.email?.toLowerCase().includes(searchParam)
          );
        });
      }
      if (sortParam && orderParam) {
        // Sort the data based on the sortParam and orderParam
        filteredData = filteredData.sort((a, b) => {
          const aValue = a[sortParam];
          const bValue = b[sortParam];

          if (orderParam === 'asc') {
            if (typeof aValue === 'string' && typeof bValue === 'string') {
              // Case-insensitive sorting for strings in ascending order
              const aValueLower = aValue.toLowerCase();
              const bValueLower = bValue.toLowerCase();
              return aValueLower < bValueLower ? -1 : 1;
            } else {
              // Regular sorting for non-string values in ascending order
              return aValue < bValue ? -1 : 1;
            }
          } else if (orderParam === 'desc') {
            if (typeof aValue === 'string' && typeof bValue === 'string') {
              // Case-insensitive sorting for strings in descending order
              const aValueLower = aValue.toLowerCase();
              const bValueLower = bValue.toLowerCase();
              return aValueLower > bValueLower ? -1 : 1;
            } else {
              // Regular sorting for non-string values in descending order
              return aValue > bValue ? -1 : 1;
            }
          }
          return 0;
        });
      }

      console.log(filteredData);

      return {
        data: filteredData, // Wrap the filtered data in a `data` property
      };



    });
}

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => {
      const data = response.data;
      console.log('User Data:', data[0]); // Log the response data
      return data[0];
    })

}

const createUser = (user: User): Promise<User | undefined> => {
  console.log("error values", user);
  console.log("orgid", orgid)

  const data = {
    first_name: user.first_name,
    last_name: user.last_name,
    title: user.title,
    suffix: "None",
    email: user.email,
    phone: "",
    organization: orgid,
    adminnotes: user.adminnotes,

  };
  const jsonData = JSON.stringify(data);
  console.log("error values", jsonData);
  return axios
    .post(`/reg/v1/admin/user/0`, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    }) // Send 'data' as the payload
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        const updatedUser = response.data;
        console.log('User Added:', updatedUser);
        return updatedUser;
      } else {
        // Handle the case where the response status is not 200 (e.g., handle errors)
        console.error('Error updating user: HTTP Status', response.status);
        throw new Error('Update failed');
      }
    })
    .then((response: Response<User>) => response.data)
    .catch((error) => {
      console.error('Error updating user:', error);
      throw error; // Re-throw the error for handling in your component
    });
}

const updateUser = (user: User): Promise<User | undefined> => {
  console.log("user values", user);
  const data = {
    first_name: user.first_name,
    last_name: user.last_name,
    title: user.title,
    suffix: user.suffix,
    email: user.email,
    phone: user.phone,
    organization: 1,
    adminnotes: user.adminnotes,
    is_locked: user.is_locked,
    is_registered: user.is_registered,
    is_deleted: user.is_deleted,
    is_active: user.is_active,
    is_inrecovery: user.is_inrecovery
  };
  console.log("data", data);
  const jsonData = JSON.stringify(data);
  console.log("jsondata", jsonData);
  return axios
    .patch(`${USER_URL}/${user.id}`, jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
    }) // Send 'data' as the payload
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        const updatedUser = response.data;
        console.log('Updated User Data:', updatedUser);
        return updatedUser;
      } else {
        // Handle the case where the response status is not 200 (e.g., handle errors)
        console.error('Error updating user: HTTP Status', response.status);
        throw new Error('Update failed');
      }
    })
    .then((response: Response<User>) => response.data)
    .catch((error) => {
      console.error('Error updating user:', error);
      throw error; // Re-throw the error for handling in your component
    });
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.get(`/reg/v1/admin/deactivate/user/${userId}`).then((response) => { console.log(response) })
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const deletePromises = userIds.map((userId) => {
    return deleteUser(userId);
  });

  // Use Promise.all to wait for all delete requests to complete
  return Promise.all(deletePromises)
    .then((results) => {
      // All delete requests have completed
      console.log("All selected users have been deleted.", results);
    })
    .catch((error) => {
      // Handle any errors that may occur during the delete requests
      console.error("Error deleting selected users:", error);
    });
}

export { getUsers, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser }
