import { SET_SELECTED_AGENCY } from './types';

const initialState = {
  selectedAgency: '',
};

const agenciesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_AGENCY:
      return { ...state, selectedAgency: action.payload };
    default:
      return state;
  }
};

export default agenciesReducer;