import { combineReducers } from 'redux';
import agenciesReducer from './agencies/reducers';
import docketsReducer from './dockets/reducers';

const rootReducer = combineReducers({
    agencies: agenciesReducer,
    dockets: docketsReducer,
    // Add other reducers as needed
});

export default rootReducer;