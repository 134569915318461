import { KTIcon } from '../../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { OrganizationsListFilter } from './OrganizationsListFilter'

const OrganizationsListToolbar = () => {
  const { setItemIdForUpdate } = useListView()
  const openAddOrganizationModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-organization-table-toolbar='base'>
      <OrganizationsListFilter />

      {/* begin::Add Organization */}
      <button type='button' className='btn btn-primary' onClick={openAddOrganizationModal}>
        <KTIcon iconName='plus' className='fs-2' />
        Add Organization
      </button>
      {/* end::Add Organization */}
    </div>
  )
}

export { OrganizationsListToolbar }
