type Props = {
  className: string
  description: string
  color: string
  img: string
  customData: Array<{ id: number; short_name: string; long_name: string; is_active: number }>;
}

const CardsWidget20 = ({ className, description, color, img, customData }: Props) => {
  return (
    <div
      className={`card card-flush bgi-no-repeat bgi-size-contain ${className}`}
      style={{
        backgroundColor: color,
        backgroundImage: `url('${img}')`,
      }}
    >
      <div className='card-header  pt-5'>
        <div className='card-title d-flex flex-column'>
          <span className='text-white  pt-1 fw-semibold fs-4'>{description}</span>
        </div>
      </div>
      <div className='card-body d-flex  pt-2'>
        <div className='d-flex align-items-center flex-column mt-4 w-100'>
          <div className='d-flex justify-content-between fw-bold fs-5 text-white opacity-75 w-100  mb-2'>
            <div className='d-flex flex-column'>
              {customData.length > 0 ? (
                customData.map((item, index) => (
                  <div key={index} className='d-flex align-items-center'>
                    <input
                      type='checkbox'
                      checked={item.is_active === 1}
                      readOnly
                      style={{ pointerEvents: 'none' }}
                      className='custom-checkbox'
                    />
                    <span className='ms-2'>{item.short_name}</span>
                  </div>
                ))
              ) : (
                <span>No subscriptions</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CardsWidget20 }