import { useListView } from '../../core/ListViewProvider'
import { OrganizationsListToolbar } from './OrganizationListToolbar'
import { OrganizationsListGrouping } from './OrganizationsListGrouping'
import { OrganizationsListSearchComponent } from './OrganizationsListSearchComponent'

const OrganizationsListHeader = () => {
  const { selected } = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <OrganizationsListSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? <OrganizationsListGrouping /> : <OrganizationsListToolbar />}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export { OrganizationsListHeader }
