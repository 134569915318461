/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Organization } from '../../core/_models'

type Props = {
  Organization: Organization
}

const OrganizationInfoCell: FC<Props> = ({ Organization }) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      <a href='#' className='text-gray-800 text-hover-primary mb-1'>
        {Organization.name}
      </a>
      <span>{Organization.website}</span>
    </div>
  </div>
)

export { OrganizationInfoCell }
