import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector} from "react-redux";



const MySubscriptions = () => {

    const selectedAgency = useSelector(state => state.agencies.selectedAgency);

    const [docketslist, setDocketslist] = useState([]);
    const [error, setError] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: 'subscribe', direction: 'asc' });
    const [alert, setAlert] = useState({ message: '', type: '' });





    useEffect(() => {
        if (selectedAgency) {
            // Fetch dockets for the selected agency
            axios.get(`/reg/v1/docket/agency/1/${selectedAgency}`)
                .then((response) => {
                    const dockets = response.data;
                    // Sort the dockets with "Unsubscribe" button first
                    const sortedDockets = dockets.sort((a, b) => (a.userid === 3.0 ? -1 : 1));
                    setDocketslist(sortedDockets);
                    setError('');
                })
                .catch((error) => {
                    setDocketslist([]);
                    setError('Error fetching dockets: ' + error.message);
                });
        }
    }, [selectedAgency]);

    useEffect(() => {
        if (sortConfig.key) {
            const sortedDockets = [...docketslist];
            sortedDockets.sort((a, b) => {
                if (sortConfig.key === 'title') {
                    return a.title.localeCompare(b.title) * (sortConfig.direction === 'asc' ? 1 : -1);
                } else if (sortConfig.key === 'docketType') {
                    return a.docketType.localeCompare(b.docketType) * (sortConfig.direction === 'asc' ? 1 : -1);
                } else if (sortConfig.key === 'modifyDate') {
                    return (new Date(a.modifyDate) - new Date(b.modifyDate)) * (sortConfig.direction === 'asc' ? 1 : -1);
                }
                return 0;
            });
            setDocketslist(sortedDockets);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortConfig]);



    const handleSubscribe = (docketId, subscribed) => {
        console.log(docketId);
        let endpoint;
        if (subscribed) {
             endpoint = `/reg/v1/docket/3/${docketId}/unsubscribe`;
        }
        else {
             endpoint = `/reg/v1/docket/3/${docketId}/subscribe`;
        }

        // Send a POST request to subscribe or unsubscribe
        axios.get(endpoint)
            .then((response) => {
                console.log(response);
                console.log(response);
                setAlert({ message: response.data, type: 'success' });
                scrollToTop();
                



                axios.get(`/reg/v1/docket/agency/1/${selectedAgency}`)
                    .then((response) => {
                        const dockets = response.data;
                        // Sort the dockets with "Unsubscribe" button first
                        const sortedDockets = dockets.sort((a, b) => (a.userid === 3.0 ? -1 : 1));
                        setDocketslist(sortedDockets);
                        setError('');
                    })
                    .catch((error) => {
                        setDocketslist([]);
                        setError('Error fetching dockets: ' + error.message);
                    });
                // Handle a successful subscription or unsubscription
                // You can update the UI or show a success message
                // For example, you can refresh the list of dockets after subscription
                // Define a function to fetch dockets
            })
            .catch((error) => {
                // Handle errors, e.g., show an error message
                console.log(error);
                if (subscribed) {
                    console.error('Error unsubscribing/', error);
                    setAlert({ message: error, type: 'danger' });
                }
                else {
                    console.error('Error subscribing/', error);
                    setAlert({ message: error, type: 'danger' });
                }
            });


    };
    const handleHeaderClick = (key) => {
        let newDirection = 'asc';

        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            newDirection = 'desc';
        }

        setSortConfig({ key, direction: newDirection });
    };

    const sortedDockets = [...docketslist];
    if (sortConfig.key === 'title') {
        sortedDockets.sort((a, b) => {
            if (a.title < b.title) return sortConfig.direction === 'asc' ? -1 : 1;
            if (a.title > b.title) return sortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        });
    } else if (sortConfig.key === 'docketType') {
        sortedDockets.sort((a, b) => {
            if (a.docketType < b.docketType) return sortConfig.direction === 'asc' ? -1 : 1;
            if (a.docketType > b.docketType) return sortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        });
    } else if (sortConfig.key === 'modifyDate') {
        sortedDockets.sort((a, b) => {
            if (a.modifyDate < b.modifyDate) return sortConfig.direction === 'asc' ? -1 : 1;
            if (a.modifyDate > b.modifyDate) return sortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        });
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'auto' // You can use 'auto' for an instant scroll
        });
    };


    return (
        <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
            <div className="d-flex flex-column flex-column-fluid">
                <div className="d-flex flex-column-auto h-70px flex-center">
                    <div className="d-flex flex-row-fluid flex-center text-center">
                        <div className="d-flex flex-column-auto h-60px flex-center mx-auto">
                            <h1 className=" text-black fw-bold fs-3">My Subscriptions</h1>
                        </div>
                    </div>
                </div>


                {error && (
                    <div className="alert alert-danger" role="alert">
                        {error}
                    </div>
                )}
                {alert.message && (
                    <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
                        {alert.message}
                        <button type="button" className="btn-close" onClick={() => setAlert({ message: '', type: '' })} aria-label="Close"></button>
                    </div>
                )}

                {docketslist.length > 0 && (
                    <div className="d-flex flex-column flex-row-fluid p-2 mt-4">
                        {/* <h2 className="text-black fw-bold fs-3 mx-auto">Dockets</h2> */}
                        <table className="table table-hover table-rounded table-striped border gy-7 gs-7">
                            <thead>
                                <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                                    <th className="col-1" style={{ whiteSpace: 'nowrap' }}>ID</th>
                                    <th style={{ whiteSpace: 'nowrap' }} onClick={() => handleHeaderClick('title')}>Title
                                        {sortConfig.key === 'title' && (
                                            <span className={`sorting-icon ${sortConfig.direction === 'asc' ? 'asc' : 'desc'}`}>
                                                <i className={`bi ${sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
                                            </span>
                                        )}</th>
                                    <th className="col-1" style={{ whiteSpace: 'nowrap' }} onClick={() => handleHeaderClick('docketType')}>
                                        Docket Type
                                        {sortConfig.key === 'docketType' && (
                                            <span className={`sorting-icon ${sortConfig.direction === 'asc' ? 'asc' : 'desc'}`}>
                                                <i className={`bi ${sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
                                            </span>
                                        )}
                                    </th>
                                    <th className="col-1" style={{ whiteSpace: 'nowrap' }} onClick={() => handleHeaderClick('modifyDate')} >
                                        Modify Date
                                        {sortConfig.key === 'modifyDate' && (
                                            <span className={`sorting-icon ${sortConfig.direction === 'asc' ? 'asc' : 'desc'}`}>
                                                <i className={`bi ${sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
                                            </span>
                                        )}
                                    </th>
                                    <th className="col-1 " style={{ whiteSpace: 'nowrap' }}>Subscribe</th>
                                </tr>
                            </thead>
                            <tbody>
                                {docketslist.map((docket) => (
                                    <tr key={docket.iddocket} className='align-top'>
                                        <td style={{ whiteSpace: 'nowrap' }}>{docket.id}</td>
                                        <td >{docket.title}</td>
                                        <td style={{ whiteSpace: 'nowrap' }}>{docket.docketType}</td>
                                        <td style={{ whiteSpace: 'nowrap' }}>{new Date(docket.modifyDate).toLocaleDateString()}</td>
                                        <td style={{ whiteSpace: 'nowrap' }}>
                                            <div className="d-flex justify-content-center">
                                                {docket.userid === 3 ? (
                                                    <button
                                                        className="btn  h-25px w-4px btn-sm d-flex align-items-center justify-content-center"
                                                        onClick={() => handleSubscribe(docket.iddocket, docket.userid === 3)}
                                                    ><i style={{ color: 'black' }} className="bi bi-x-circle"></i></button>

                                                ) : (
                                                    <button
                                                        className="btn  h-25px w-4px btn-sm d-flex align-items-center justify-content-center"
                                                        onClick={() => handleSubscribe(docket.iddocket, docket.userid === 3)}
                                                    ><i style={{ color: 'Dodgerblue' }} className="bi bi-check-circle-fill"></i></button>

                                                )

                                                }

                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MySubscriptions;
