/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { ApexOptions } from "apexcharts";
import ReactApexChart from 'react-apexcharts';
type Props = {
  className: string
  topicsdata?: {
    labels: string[];
    values: number[];
  }
}

const ListsWidget2: React.FC<Props> = ({ className, topicsdata }) => {
  const chartData: ApexOptions = {
    chart: {
      type: 'bar',
      height: 6, // Ensure that chartHeight is converted to a number
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '45%',


      },
    },
    dataLabels: {
      enabled: false,

    },
    xaxis: {
      categories: topicsdata?.labels || []
    },
    colors: ['#ff1e4f'],
  };

  const series = [
    {
      name: 'Values',
      data: topicsdata?.values || []
    },
  ];
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bold text-dark'>Topics</h3>

      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-2'>
        {/* begin::Item */}
        <ReactApexChart options={chartData} series={series} type='bar' />

        {/* end::Item */}
        {/* begin::Item */}

        {/* end::Item */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export { ListsWidget2 }
