/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { SidebarMenuItem } from './SidebarMenuItem'

const SidebarMenuMain = () => {
  

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='home'
        title='Home'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/docket'
        icon='folder'
        title='Dockets'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/calendar'
        icon='calendar'
        title='Calendar'
        fontIcon='calendar'
      />
      <SidebarMenuItem
        to='/documents'
        icon='document'
        title='Documents'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/comments'
        icon='message-text-2'
        title='Comments'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/notes'
        icon='note'
        title='Notes'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/forecast'
        icon='watch'
        title='Forecast'
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/reports'
        icon='chart'
        title='Reports'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/admin'
        icon='profile-user'
        title='Admin'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/super-admin'
        icon='user'
        title='Super Admin'
        fontIcon='bi-app-indicator'
      />





    </>
  )
}

export { SidebarMenuMain }
