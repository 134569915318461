import axios from 'axios';
export interface IProfileDetails {
  avatar: string
  name: string
  primaryuser: string | number
  fullname: string
  industry: string
  phone: string
  website: string
  country: string
  language: string
  timeZone: string
  currency: string
  communications: {
    email: boolean
    phone: boolean
  }
  allowMarketing: boolean
  address: string;
  city: string;
  state: string;
  region: string;
  zip: string;
}

export interface IUpdateEmail {
  newEmail: string
  confirmPassword: string
}

export interface IUpdatePassword {
  currentPassword: string
  newPassword: string
  passwordConfirmation: string
}

export interface IConnectedAccounts {
  google: boolean
  github: boolean
  stack: boolean
}

export interface IEmailPreferences {
  successfulPayments: boolean
  payouts: boolean
  freeCollections: boolean
  customerPaymentDispute: boolean
  refundAlert: boolean
  invoicePayments: boolean
  webhookAPIEndpoints: boolean
}

export interface INotifications {
  notifications: {
    email: boolean
    phone: boolean
  }
  billingUpdates: {
    email: boolean
    phone: boolean
  }
  newTeamMembers: {
    email: boolean
    phone: boolean
  }
  completeProjects: {
    email: boolean
    phone: boolean
  }
  newsletters: {
    email: boolean
    phone: boolean
  }
}

export interface IDeactivateAccount {
  confirm: boolean
}

export const getProfileDetailsInitValues = async (): Promise<IProfileDetails | null> => {
  try {
    const response = await axios.get('/reg/v1/admin/organization/1'); // Replace '1' with the actual org ID you want to fetch
    const orgData = response.data[0];
    return {
      avatar: '/media/avatars/300-1.jpg',
      name: orgData.name || '',
      primaryuser: orgData.primaryuser || '',
      industry: orgData.industry || '',
      fullname: orgData.fullname || '',

      phone: orgData.phone || '',
      website: orgData.website || '',
      country: orgData.country || '',
      language: orgData.language || '',
      timeZone: orgData.region || '', // Map 'region' to 'timeZone' or adjust as needed
      currency: '', // You may need to fetch this from the backend as well
      communications: {
        email: false,
        phone: false,
      },
      allowMarketing: orgData.is_partner === 1,
      address: orgData.address || '',
      city: orgData.city || '',
      state: orgData.state || '',
      region: orgData.region || '',
      zip: orgData.zip || '',
    };
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
};

export const updateEmail: IUpdateEmail = {
  newEmail: 'support@keenthemes.com',
  confirmPassword: '',
}

export const updatePassword: IUpdatePassword = {
  currentPassword: '',
  newPassword: '',
  passwordConfirmation: '',
}

export const connectedAccounts: IConnectedAccounts = {
  google: true,
  github: true,
  stack: false,
}

export const emailPreferences: IEmailPreferences = {
  successfulPayments: false,
  payouts: true,
  freeCollections: false,
  customerPaymentDispute: true,
  refundAlert: false,
  invoicePayments: true,
  webhookAPIEndpoints: false,
}

export const notifications: INotifications = {
  notifications: {
    email: true,
    phone: true,
  },
  billingUpdates: {
    email: true,
    phone: true,
  },
  newTeamMembers: {
    email: true,
    phone: false,
  },
  completeProjects: {
    email: false,
    phone: true,
  },
  newsletters: {
    email: false,
    phone: false,
  },
}

export const deactivateAccount: IDeactivateAccount = {
  confirm: false,
}
