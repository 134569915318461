import { FC } from 'react'

type Props = {
  is_partner?: number
}

const OrganizationPartnerCell: FC<Props> = ({ is_partner }) => (
  <div className={`badge ${is_partner === 1 ? 'badge-success' : 'badge-light'} fw-bolder`}>
    {is_partner === 1 ? 'Partner' : 'Not a Partner'}
  </div>)

export { OrganizationPartnerCell }
