/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from "apexcharts";
type Props = {
  className: string;
  chartColor: string;
  chartHeight: string;
  docketsdata?: {
    labels: string[];
    values: number[];

  };
  title: string;
}

const MixedWidget8: React.FC<Props> = ({ className, chartColor, chartHeight, docketsdata, title }) => {


  const chartData: ApexOptions = {
    chart: {
      type: 'bar',
      height: parseInt(chartHeight), // Ensure that chartHeight is converted to a number
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        
        
      },
    },
    dataLabels: {
      enabled: false,
      
    },
    xaxis: {
      categories: docketsdata?.labels || [],
    },
    colors: [chartColor],
  };

  const series = [
    {
      name: 'Values',
      data: docketsdata?.values || [],
    },
  ];



  return (
    <div className={`card ${className}`}>
      {/* begin::Beader */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>

          <span className='text-muted fw-semibold fs-7'>Latest trends</span>
        </h3>


      </div>

      <div className='card-body d-flex flex-column'>

      <ReactApexChart options={chartData} series={series} type='bar' height={parseInt(chartHeight)} />


      </div>

    </div>
  )
}


export { MixedWidget8 }
