import React, { useState, useEffect } from "react";


import axios from "axios";
import { useSelector, useDispatch } from "react-redux"; // Import react-redux hooks
import { setSelectedAgency } from "../../../redux/agencies/actions";



const MyAgencies = () => {

    const [agencies, setAgencies] = useState([]);
    const selectedAgency = useSelector(state => state.agencies.selectedAgency);
    const dispatch = useDispatch();

    useEffect(() => {
        // Make an API call to fetch My Agencies data
        axios.get("/reg/v1/agency/").then((response) => {
            // Assuming the API response contains an array of agencies
            const { data } = response;
            if (data.length > 0) {
                setAgencies(data);
                // If there are agencies, set the first agency as the default
                dispatch(setSelectedAgency(data[0].id));
            }
        }).catch((error) => {
            console.error("Error fetching My Agencies:", error);
        });
    }, [dispatch]);
    const handleAgencyChange = (e) => {
        // Dispatch an action to update selectedAgency in the Redux store
        dispatch(setSelectedAgency(e.target.value));
    };




    return (
        <div className="d-flex align-items-center">
            <span className="fs-7 fw-bold text-black-700 flex-shrink-0 pe-4 d-none d-md-block">
                My Agencies
            </span>
            <select
                className="form-select form-select-sm w-125px form-select-solid me-6"
                data-control="select2"
                data-placeholder="Select Agency"
                value={selectedAgency || (agencies.length > 0 ? agencies[0].id : "")}
                onChange={handleAgencyChange} // Call handleAgencyChange on change
            >
                {/* <option value="">Select Agency</option> */}
                {Array.isArray(agencies) && agencies.length > 0 &&  // Check if agencies array is not empty
                    agencies.map((agency) => (
                        <option key={agency.id} value={agency.id}>
                            {agency.short_name}
                        </option>
                    ))
                }
            </select>
        </div>
    );
};

export default MyAgencies;











