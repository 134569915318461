import { Navigate, Routes, Route } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { Users } from './Users'
import { ProfileHeader2 } from './ProfileHeader'
import { Settings } from './Settings'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Admin',
    path: '/admin',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProfilePage = () => (
  <>
  <ProfileHeader2 />
  <Routes>
    {/* <Route
      element={
        <>
          <ProfileHeader2 />
          <Outlet />
        </>
      }
    > */}
      <Route
        path='users'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Users</PageTitle>
            <Users />
          </>
        }
      />
      <Route
        path='settings'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}> Settings</PageTitle>
            <Settings />
          </>
        }
      />

      <Route index element={<Navigate to='/admin/users' />} />
    {/* </Route> */}
  </Routes>
  </>
)

export default ProfilePage
