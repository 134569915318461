/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { ApexOptions } from "apexcharts";
import ReactApexChart from 'react-apexcharts';
type Props = {
  className: string
  sentimentdata?: {
    labels: string[];
    values: number[];
  }
}

const ListsWidget6: React.FC<Props> = ({ className, sentimentdata }) => {
  const chartData: ApexOptions = {
    chart: {
      type: 'line',
      height: 6, // Ensure that chartHeight is converted to a number
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '20%',


      },
    },
    dataLabels: {
      enabled: false,

    },
    xaxis: {
      categories: sentimentdata?.labels || []
    },
    colors: ['#0000ff'],
  };

  const series = [
    {
      name: 'Values',
      data: sentimentdata?.values || []
    },
  ];
  return (
    <div className='card card-xl-stretch mb-5 mb-xl-8'>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bold text-dark'>Public Sentiment</h3>

      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-2'>
        <ReactApexChart options={chartData} series={series} type='line' />

      </div>
      {/* end::Body */}
    </div>
  )
}

export { ListsWidget6 }
