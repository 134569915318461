import { FC } from 'react'

type Props = {
  last_login?: number
}

const UserLastLoginCell: FC<Props> = ({ last_login }) => (
  <div className={`badge ${last_login === 1 ? 'badge-success' : 'badge-light'} fw-bolder`}>
    {last_login === 1 ? 'Active' : 'Inactive'}
  </div>)

export { UserLastLoginCell }
